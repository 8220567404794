// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore} from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA24XcZuRmZMFG9WRVjwfAOdd6SMk2XHkQ",
  authDomain: "secondresume.firebaseapp.com",
  projectId: "secondresume",
  storageBucket: "secondresume.appspot.com",
  messagingSenderId: "258830063351",
  appId: "1:258830063351:web:f1913d5eba0013bfeea5f2",
  measurementId: "G-NTR07XJ39D"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
