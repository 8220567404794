import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './JobResults.module.css';

import { useState, useEffect } from 'react';

import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import {collection, getDocs } from 'firebase/firestore/lite';
import {app, db} from '../../util/firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function JobResults() {

    const params = useParams();
    let navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [jobs, setJobs] = useState({});

    useEffect(() => {
        getJobs(db)
        .then((jobs) => {
            setJobs(jobs)
        })
    },[setJobs]);

    async function getJobs(db) {
        const jobsCol = collection(db, 'jobs');
        const jobsSnapshot = await getDocs(jobsCol);
        const jobsData = jobsSnapshot.docs.filter(doc => doc._document.data.value.mapValue.fields.position.stringValue.toLowerCase() === searchParams.get('title').toLowerCase() && doc._document.data.value.mapValue.fields.city.stringValue.toLowerCase() === searchParams.get('location').toLowerCase());
        return jobsData;
    };

    function handleJobClick(id) {
        navigate(`/job?id=${id}`);
    };

    return (
        <Container className={styles.JobResults}>
            <Row style={{ padding: "0 11%" }}>
                <span style={{ margin: "0 0 1rem 0", color: "#918f8f" }}>'{searchParams.get('title')}' jobs in {searchParams.get('location')}</span>
                <span style={{ margin: "0 0 1rem 0" }}>{jobs.length > 0? jobs.length : 0} {jobs.length === 1 ? "result" : "results"}</span>
                <Col md={8}>
                    <Row>
                        {jobs.length > 0 ? 
                            jobs.map((job) => (
                                <div className={styles.Card}>
                                <h3 onClick={() => handleJobClick(job.id)}>{job._document.data.value.mapValue.fields.position.stringValue}</h3>
                                <h5>{job._document.data.value.mapValue.fields.employer.stringValue}</h5>
                                <p>{job._document.data.value.mapValue.fields.city.stringValue}</p>
                                <p>${job._document.data.value.mapValue.fields.salaryRange.mapValue.fields.min.stringValue} - ${job._document.data.value.mapValue.fields.salaryRange.mapValue.fields.max.stringValue}</p>
                                <ul>
                                    <li>Entry Level Position</li>
                                    <li>Friendly and Collaborative Working Environment</li>
                                    <li>Full Time Permanent Role</li>
                                </ul>
                                <p>Looking for {searchParams.get('title')} to ...</p>
                                </div> 
                            )):
                            <p>No results</p>
                        }
                    </Row> 
                </Col>
                <Col md={4} className={styles.NeedSomeHelp}>
                    <h5>Need some help with the process?</h5>
                    <img src="https://i1.lensdump.com/i/tEYuY5.png" width="35%"/>
                    <p>We're available for consultations, resume reviews and much more!</p>
                    <button className={GlobalStyles.DarkButton}>Contact Us</button>
                </Col>
            </Row>
        </Container>
    )
};

export default JobResults;