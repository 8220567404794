import './App.css';

import React, { Suspense, useState, useEffect } from 'react';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { getAuth, onAuthStateChanged, updateProfile, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import {collection, getDocs, updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore/lite';
import { app, db } from "./util/firebase";


import Home from './pages/Home/Home';
import JobResults from './pages/JobResults/JobResults';
import JobPosting from './pages/JobPosting/JobPosting';
import JobApply from './pages/JobApply/JobApply';
import SavedJobs from './pages/SavedJobs/SavedJobs';
import SignIn from './pages/SignIn/SignIn';
import Register from './pages/Register/Register';
import Dashboard from './pages/Dashboard/Dashboard';
import CreateResume from './pages/CreateResume/CreateResume';
import Settings from './pages/Settings/Settings';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';


const auth = getAuth();
const user = auth.currentUser;

let activeUser;

function App() {

  // Jobs States
  const [savedJobs, setSavedJobs] = useState([]);
  const [jobPosting, setJobPosting] = useState([]);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            activeUser = {...user};             
        } else {
    
        }
    })
  }, [auth]);

  useEffect(() => {
      getUsers(db)
      .then((data) => {
          // User Info
          setProfile({...data[0]._document.data.value.mapValue.fields})
          // Saved Jobs
          const {savedJobs} = data[0]._document.data.value.mapValue.fields;
          if (savedJobs.arrayValue.values) {
              savedJobs.arrayValue.values.map((job) => {
                  getSavedJobs(db, job.stringValue)
                  .then((result) => {
                      result.map((data) => {
                          setSavedJobs((prevValues) => {
                              return [
                                  ...prevValues.filter(job => job.id !== data.id),
                                  {...data._document.data.value.mapValue.fields, id: data.id}
                              ]
                          })
                      })
                  })
              })
          }
      })
  },[setSavedJobs, setProfile])

  async function getUsers(db) {
      const usersCol = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCol);
      const userData = usersSnapshot.docs.filter(doc => doc.id === activeUser.uid);
      // const userData = usersList[0]._document.data.value.mapValue.fields;
      return userData;
  };

  async function getSavedJobs(db, jobId) {
      const savedJobsCol = collection(db, 'jobs');
      const savedJobsSnapshot = await getDocs(savedJobsCol);
      const savedJobsData = savedJobsSnapshot.docs.filter(doc => doc.id === jobId);
      // const userData = usersList[0]._document.data.value.mapValue.fields;
      return savedJobsData;
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Header />
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/jobs/' element={<JobResults />}/>
          <Route path='/job/' element={<JobPosting savedJobs={savedJobs}/>}/>
          <Route path='/job/:jobId/apply' element={<JobApply />}/>
          <Route path='/login' element={<SignIn />}/>
          <Route path='/register' element={<Register />}/>
          <Route path='/dashboard' element={<Dashboard/>}/>
          <Route path='/dashboard/create-resume' element={<CreateResume />}/>
          <Route path='/activity/saved-jobs' element={<SavedJobs savedJobs={savedJobs} activeUser={activeUser} setSavedJobs={setSavedJobs}/>}/>
          <Route path='/settings' element={<Settings user={activeUser} profile={profile} auth={auth}/>}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
