import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './CreateResume.module.css';

import React, { useState, useEffect, Suspense } from 'react';
import { useInView } from 'react-intersection-observer';

import {collection, getDocs, updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore/lite';
import { app, db } from "../../util/firebase";
import { getAuth, onAuthStateChanged, updateProfile } from "firebase/auth";

import { useSearchParams } from 'react-router-dom';

import { v4 as uuid } from 'uuid';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import VideoRecorder from '../../components/VideoRecorder/VideoRecorder';

const EditPersonalDetailsFormSuspense = React.lazy(() => import('../../components/EditPersonalDetailsForm/EditPersonalDetailsForm'));

const auth = getAuth();

let activeUser;

let currentYear = new Date().getFullYear();
let earliestYear = 1922;

let yearsArray = [];

const skillsArray = ["C# Programming", "Javascript Programming", "Microsoft Dynamics"];
const languagesArray = ["Spanish", "French", "Italian", "Japanese", "Mandarin", "Hindi", "Farsi"]

function CreateResume() {

    const [searchParams, setSearchParams] = useSearchParams();

    const [profile, setProfile] = useState({});

    const [showList, setShowList] = useState({});

    const [resumeData, setResumeData] = useState({
        firstName: '',
        lastName: '',
        location: '',
        phoneNumber: '',
        email: '',
        careerHistory: [],
        education: [],
        licenses: [],
        skills: [],
        languages: [],
        personalSummary: ''
    })

    const [jobRole, setJobRole] = useState({
        title: '',
        companyName: '',
        startedMonth: '',
        startedYear: '',
        endedMonth: '',
        endedYear: '',
        description: ''
    })

    const [deleteJobRole, setDeleteJobRole] = useState({
        title: '',
        companyName: '',
        startedMonth: '',
        startedYear: '',
        endedMonth: '',
        endedYear: '',
        description: ''
    })

    const [educationItem, setEducationItem] = useState({
        institution: '',
        course: '',
        finishedYear: '',
        courseHighlights: ''
    })

    const [deleteEducationItem, setDeleteEducationItem] = useState({
        institution: '',
        course: '',
        finishedYear: '',
        courseHighlights: ''
    })

    const [licenseItem, setLicenseItem] = useState({
        licenseName: '',
        issuingOrganisation: '',
        issuedMonth: '',
        issuedYear: '',
        expiryMonth: '',
        expiryYear: '',
        description: ''
    });

    const [deleteLicenseItem, setDeleteLicenseItem] = useState({
        licenseName: '',
        issuingOrganisation: '',
        issuedMonth: '',
        issuedYear: '',
        expiryMonth: '',
        expiryYear: '',
        description: ''
    });

    const [skillsItem, setSkillsItem] = useState({
        skillName: ''
    });

    const [languageItem, setLanguageItem] = useState({
        languageName: ''
    });

    const [personalSummary, setPersonalSummary] = useState({
        summary: ''
    });

    const [deletepersonalSummary, setDeletePersonalSummary] = useState({
        summary: ''
    });

    const [modalShow, setModalShow] = useState({
        careerHistory: false
    });

    const [tab, setTab] = useState({});

    const { ref: PersonalDetailsRef , inView: personalDetailsCardIsVisible } = useInView({
        onChange: () => {
            setTab({})
        }
    });
    const { ref: CareerHistoryRef , inView: careerHistoryCardIsVisible } = useInView({
        onChange: () => {
            setTab({})
        }
    });
    const { ref: EducationRef , inView: educationCardIsVisible } = useInView({
        onChange: (inView, entry) => {
            setTab({})
        }
    });
    const { ref: LicenseRef , inView: licenseCardIsVisible } = useInView({
        onChange: (inView, entry) => {
            setTab({})
        }
    });
    const { ref: skillsRef , inView: skillsCardIsVisible } = useInView({
        onChange: (inView, entry) => {
            setTab({})
        }
    });
    const { ref: languagesRef , inView: languagesCardIsVisible } = useInView({
        onChange: (inView, entry) => {
            setTab({})
        }
    });
    const { ref: videoRef , inView: videoCardIsVisible } = useInView({
        onChange: (inView, entry) => {
            setTab({})
        }
    });
    const { ref: personalSummaryRef , inView: personalSummaryCardIsVisible } = useInView({
        onChange: (inView, entry) => {
            setTab({})
        }
    });

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                activeUser = {...user};
                setProfile({...user});               
            } else {
        
            }
        })
    }, [auth]);


    useEffect(() => {
        getUsers(db)
        .then((data) => {
            const {firstName, lastName, location, phoneNumber, email, careerHistory, education, licenses, skills, languages, personalSummary} = data[0]._document.data.value.mapValue.fields;
            setResumeData((prevValues) => {
                return {
                    ...prevValues,
                    firstName: firstName.stringValue,
                    lastName: lastName.stringValue,
                    location: location.stringValue,
                    phoneNumber: phoneNumber.stringValue,
                    email: email.stringValue,
                    careerHistory: careerHistory.arrayValue.values && careerHistory.arrayValue.values.length > 0 ? careerHistory.arrayValue.values : [] ,
                    education: education.arrayValue.values && education.arrayValue.values.length > 0 ? education.arrayValue.values : [],
                    licenses: licenses.arrayValue.values && licenses.arrayValue.values.length > 0 ? licenses.arrayValue.values : [],
                    skills: skills.arrayValue.values && skills.arrayValue.values.length > 0 ? skills.arrayValue.values : [],
                    languages: languages.arrayValue.values && languages.arrayValue.values.length > 0 ? languages.arrayValue.values : [],
                    personalSummary: personalSummary.stringValue
                }
            })
        })
    }, [setResumeData])

    useEffect(() => {
        while (currentYear >= earliestYear) {
            yearsArray.push(currentYear);
            currentYear -= 1;
        };
    }, []);

    async function getUsers(db) {
        const usersCol = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCol);
        const userData = usersSnapshot.docs.filter(doc => doc.id === activeUser.uid);
        // const userData = usersList[0]._document.data.value.mapValue.fields;
        return userData;
    };


    useEffect(() => {
        if (searchParams.get('edit')) {
            if (document.getElementById(searchParams.get('item'))) {
                document.getElementById(searchParams.get('item')).scrollIntoView(true);
                switch (searchParams.get('edit')) {
                    case "personal-summary":
                        openUserPersonalSummaryEditModal();                      
                        break;
                    case "career-history":
                        resumeData.careerHistory.map((item) => {
                            if (item.mapValue.fields.id.stringValue === searchParams.get('item')) {
                                openUserCareerHistoryEditModal(item);
                            } 
                        })                        
                        break;
                    case "education":
                        resumeData.education.map((item) => {
                            if (item.mapValue.fields.id.stringValue === searchParams.get('item')) {
                                openUserEducationHistoryEditModal(item);
                            }
                        })
                        break;
                    case "licenses":
                        resumeData.licenses.map((item) => {
                            if (item.mapValue.fields.id.stringValue === searchParams.get('item')) {
                                openUserLicenseEditModal(item);
                            }
                        })
                        break;                    
                    default:
                        break;
                }
            }
        }
        if (searchParams.get('add')) {
            document.getElementById(searchParams.get('add')).scrollIntoView(true);
            switch (searchParams.get('add')) {
                case "career-history":
                    setModalShow({careerHistory: true});                    
                    break;
                case "education":
                    setModalShow({education: true})
                    break;
                case "licenses":
                    setModalShow({licenses: true})
                    break;
                case "skills":
                    setModalShow({skills: true})
                    break;
                case "languages":
                    setModalShow({languages: true})
                    break;
                default:
                    break;
            }
        }
    },[searchParams.get('edit'), resumeData.careerHistory, document.getElementById(searchParams.get('item'))])

    // Career History

    function handleCareerHistoryInputChange(event) {
        const {name, value} = event.target;
        setJobRole((prevValues) => {
            return {
                ...prevValues,
                [name]: value
            }
        })
    };

    async function addUserCareerHistory(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        await updateDoc(userRef, {
            careerHistory: arrayUnion({
                id: uuid(),
                jobTitle: jobRole.title,
                companyName: jobRole.companyName,
                startedMonth: jobRole.startedMonth.length > 0 ? jobRole.startedMonth : 'Jan',
                startedYear: jobRole.startedYear.length > 0 ? jobRole.startedYear : '2022',
                endedMonth: jobRole.endedMonth.length > 0 ? jobRole.endedMonth: 'Jan',
                endedYear: jobRole.endedYear.length > 0 ? jobRole.endedYear : '2022',
                description: jobRole.description
            })
        });
        window.location.reload();
    };

    function openUserCareerHistoryEditModal(item) {
        const {id, jobTitle, companyName, startedMonth, startedYear, endedMonth, endedYear, description} = item.mapValue.fields;
        setJobRole({
            id: id.stringValue,
            title: jobTitle.stringValue,
            companyName: companyName.stringValue,
            startedMonth: startedMonth.stringValue,
            startedYear: startedYear.stringValue,
            endedMonth: endedMonth.stringValue,
            endedYear: endedYear.stringValue,
            description: description.stringValue 
        })
        setDeleteJobRole({
            id: id.stringValue,
            title: jobTitle.stringValue,
            companyName: companyName.stringValue,
            startedMonth: startedMonth.stringValue,
            startedYear: startedYear.stringValue,
            endedMonth: endedMonth.stringValue,
            endedYear: endedYear.stringValue,
            description: description.stringValue 
        })
        setModalShow({careerHistoryUpdate : true})
    };

    async function updateUserCareerHistory(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        // First remove the existing career object
        await updateDoc(userRef, {
            careerHistory: arrayRemove({
                id: deleteJobRole.id,
                jobTitle: deleteJobRole.title,
                companyName: deleteJobRole.companyName,
                startedMonth: deleteJobRole.startedMonth,
                startedYear: deleteJobRole.startedYear,
                endedMonth: deleteJobRole.endedMonth,
                endedYear: deleteJobRole.endedYear, 
                description: deleteJobRole.description
            })
        })
        // Add updated career object
        await updateDoc(userRef, {
            careerHistory: arrayUnion({
                id: jobRole.id,
                jobTitle: jobRole.title,
                companyName: jobRole.companyName,
                startedMonth: jobRole.startedMonth,
                startedYear: jobRole.startedYear,
                endedMonth: jobRole.endedMonth,
                endedYear: jobRole.endedYear, 
                description: jobRole.description
            })
        })
        window.location.reload();
    };

    // Education

    function handleEducationInputChange(event) {
        const {name, value} = event.target;
        setEducationItem((prevValues) => {
            return {
                ...prevValues,
                [name]: value
            }
        })
    };

    async function addUserEducation(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        await updateDoc(userRef, {
            education: arrayUnion({
                id: uuid(),
                institution: educationItem.institution,
                course: educationItem.course,
                finishedYear: educationItem.finishedYear.length > 0 ? educationItem.finishedYear : '2022',

            })
        });
        window.location.reload();
    };

    function openUserEducationHistoryEditModal(item) {
        const {institution, course, finishedYear, id} = item.mapValue.fields;
        setEducationItem({
            id: id.stringValue,
            institution: institution.stringValue,
            course: course.stringValue,
            finishedYear: finishedYear.stringValue
        })

        setDeleteEducationItem({
            id: id.stringValue,
            institution: institution.stringValue,
            course: course.stringValue,
            finishedYear: finishedYear.stringValue
        })
        setModalShow({educationUpdate : true})
    };

    async function updateUserEducation(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        // First remove the existing education object
        await updateDoc(userRef, {
            education: arrayRemove({
                id: deleteEducationItem.id,
                institution: deleteEducationItem.institution,
                course: deleteEducationItem.course,
                finishedYear: deleteEducationItem.finishedYear
            })
        })
        // Add updated education object
        await updateDoc(userRef, {
            education: arrayUnion({
                id: educationItem.id,
                institution: educationItem.institution,
                course: educationItem.course,
                finishedYear: educationItem.finishedYear
            })
        })
        window.location.reload();
    };

    // Licenses

    function handleLicenseInputChange(event) {
        const {name, value} = event.target;
        setLicenseItem((prevValues) => {
            return {
                ...prevValues,
                [name]: value
            }
        })
    };

    async function addUserLicense(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        console.log(licenseItem);
        await updateDoc(userRef, {
            licenses: arrayUnion({
                id: uuid(),
                licenseName: licenseItem.licenseName,
                issuingOrganisation: licenseItem.issuingOrganisation,
                issuedMonth: licenseItem.issuedMonth.length > 0 ? licenseItem.issuedMonth : 'Jan',
                issuedYear: licenseItem.issuedYear.length > 0 ? licenseItem.issuedYear : '2022',
                expiryMonth: licenseItem.expiryMonth.length > 0 ? licenseItem.expiryMonth : 'Jan',
                expiryYear: licenseItem.expiryYear.length > 0 ? licenseItem.expiryYear : '2022',
                description: licenseItem.description

            })
        });
        window.location.reload();
    };

    function openUserLicenseEditModal(item) {
        const {licenseName, issuingOrganisation, issuedMonth, issuedYear, expiryMonth, expiryYear, description, id} = item.mapValue.fields;
        setLicenseItem({
            id: id.stringValue,
            licenseName: licenseName.stringValue,
            issuingOrganisation: issuingOrganisation.stringValue,
            issuedMonth: issuedMonth.stringValue,
            issuedYear: issuedYear.stringValue,
            expiryMonth: expiryMonth.stringValue,
            expiryYear: expiryYear.stringValue,
            description: description.stringValue

        })

        setDeleteLicenseItem({
            id: id.stringValue,
            licenseName: licenseName.stringValue,
            issuingOrganisation: issuingOrganisation.stringValue,
            issuedMonth: issuedMonth.stringValue,
            issuedYear: issuedYear.stringValue,
            expiryMonth: expiryMonth.stringValue,
            expiryYear: expiryYear.stringValue,
            description: description.stringValue
        })
        setModalShow({licensesUpdate : true})
    };
    
    async function updateUserLicense(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        // First remove the existing license object
        await updateDoc(userRef, {
            licenses: arrayRemove({
                id: deleteLicenseItem.id,
                licenseName: deleteLicenseItem.licenseName,
                issuingOrganisation: deleteLicenseItem.issuingOrganisation,
                issuedMonth: deleteLicenseItem.issuedMonth,
                issuedYear: deleteLicenseItem.issuedYear,
                expiryMonth: deleteLicenseItem.expiryMonth,
                expiryYear: deleteLicenseItem.expiryYear,
                description: deleteLicenseItem.description
            })
        })
        // Add updated license object
        await updateDoc(userRef, {
            licenses: arrayUnion({
                id: licenseItem.id,
                licenseName: licenseItem.licenseName,
                issuingOrganisation: licenseItem.issuingOrganisation,
                issuedMonth: licenseItem.issuedMonth,
                issuedYear: licenseItem.issuedYear,
                expiryMonth: licenseItem.expiryMonth,
                expiryYear: licenseItem.expiryYear,
                description: licenseItem.description
            })
        })
        window.location.reload();
    };

    // Skills

    function handleSkillsInputChange(event) {
        const {value} = event.target;
        setTimeout(setShowList({skills : true}), 200)
        setSkillsItem(() => {
            return {
                skillName: value
            }
        })
    };

    async function addUserSkill(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        await updateDoc(userRef, {
            skills: arrayUnion(skillsItem.skillName)
        });
        window.location.reload();
    };

    async function deleteUserSkill(db, skillToDelete) {
        const userRef = doc(db, 'users', activeUser.uid);
        await updateDoc(userRef, {
            skills: arrayRemove(skillToDelete)
        });
        window.location.reload();
    };

    // Languages

    function handleLanguagesInputChange(event) {
        const {value} = event.target;
        setTimeout(setShowList({languages : true}), 200)
        setLanguageItem(() => {
            return {
                languageName: value
            }
        })
    };

    async function addUserLanguage(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        await updateDoc(userRef, {
            languages: arrayUnion(languageItem.languageName)
        });
        window.location.reload();
    };

    async function deleteUserLanguage(db, languageToDelete) {
        const userRef = doc(db, 'users', activeUser.uid);
        await updateDoc(userRef, {
            languages: arrayRemove(languageToDelete)
        });
        window.location.reload();
    };

    function handleTabClick(elementName, elementId) {
        setTab({[elementName]: true})
        document.getElementById(elementId).scrollIntoView();
    };

    // Personal Summary

    function handlePersonalSummaryInputChange(event) {
        const {value} = event.target;
        setPersonalSummary(() => {
            return {
                summary: value
            }
        })
    };

    function openUserPersonalSummaryEditModal() {
        setPersonalSummary({
            summary: resumeData.personalSummary
        })

        setDeletePersonalSummary({
            summary: resumeData.personalSummary
        })
        setModalShow({personalSummaryUpdate : true})
    };

    async function addUserPersonalSummary(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        await updateDoc(userRef, {
            personalSummary: personalSummary.summary
        })
        window.location.reload();
    };
      
    function handleResultClick(event, name, value) {
        event.cancelBubble = true;
        if (name === "skill") {
            setTimeout(setSkillsItem(() => {
                return {
                    skillName : value
                }
            }),1000)
            setShowList({});
        }
        if (name === "language") {
            setTimeout(setLanguageItem(() => {
                return {
                    languageName : value
                }
            }),1000)
            setShowList({});
        }
      };

    return (
        <div className={styles.CreateResume}>
            <div>
                <Container className={styles.TabMenu} fluid>
                    <h4>Create a resumé</h4>
                    <nav>
                        <div style={{display: 'flex', }}>
                            <div>
                                <a onClick={() => handleTabClick('video','video')}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>-----</span>
                                        {tab.video || videoCardIsVisible ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>-----</span>
                                    </div>
                                    <h6>Video</h6>
                                </a>
                            </div>
                            <div>
                                <a onClick={() => handleTabClick('personalSummary','personal-summary')}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>-----</span>
                                        {tab.personalSummary || personalSummaryCardIsVisible ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>-----</span>
                                    </div>
                                    <h6>Personal <br/> Summary</h6>
                                </a>
                            </div>
                            <div>
                                <a onClick={() => handleTabClick('personalDetails','personal-details')}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>-----</span>
                                        {tab.personalDetails || personalDetailsCardIsVisible ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>-----</span>
                                    </div>
                                    <h6>Personal <br/>Details</h6>
                                </a>
                            </div>
                            <div>
                                <a onClick={() => handleTabClick('careerHistory','career-history')}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>-----</span>
                                        {tab.careerHistory || careerHistoryCardIsVisible ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>-----</span>
                                    </div>
                                    <h6>Career <br/>History</h6>
                                </a>
                            </div>
                            <div>
                                <a onClick={() => handleTabClick('education','education')}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>-----</span>
                                        {tab.education || educationCardIsVisible ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>-----</span>
                                    </div>
                                    <h6>Education</h6>
                                </a>
                            </div>
                            <div>
                                <a onClick={() => handleTabClick('licenses','licenses')}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>-----</span>
                                        {tab.licenses || licenseCardIsVisible ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>-----</span>
                                    </div>
                                    <h6>Licenses</h6>
                                </a>
                            </div>
                            <div>
                                <a onClick={() => handleTabClick('skills','skills')}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>-----</span>
                                        {tab.skills || skillsCardIsVisible ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>-----</span>
                                    </div>
                                    <h6>Skills</h6>
                                </a>
                            </div>
                            <div>
                                <a onClick={() => handleTabClick('languages','languages')}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>-----</span>
                                        {tab.languages || languagesCardIsVisible ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>-----</span>
                                    </div>
                                    <h6>Languages</h6>
                                </a>
                            </div>
                        </div>
                    </nav>
                </Container>
                <Container className={styles.Body} fluid>

                    <div className={styles.Card} ref={videoRef}>
                        <div className={styles.CardBody}>
                            <h5>Upload Video</h5>
                            <p>Introduce yourself in a video in 60 seconds or less and we'll send it directly to employers.</p>
                            <VideoRecorder /> 
                        </div>
                        <button className={GlobalStyles.BlueButton} style={{ marginTop: "1rem", marginLeft: "1.2rem"}} onClick={() => handleTabClick('personalSummary','personal-summary')}>Next</button>   
                    </div> 

                    <div className={styles.Card} ref={personalSummaryRef} style={{ marginBottom: modalShow.personalSummaryUpdate || modalShow.personalSummary ? "15rem" : "10rem" }}>
                        <span id="personal-summary" className={styles.ScrollMarker}></span>
                        <div className={styles.CardBody}>
                            {
                                modalShow.personalSummary? (
                                    <div className={`${styles.Modal} ${styles.SkillsModal}`} style={{display: modalShow.personalSummary ? 'block' : 'none'}}>
                                        <h5>Add Personal Summary</h5>
                                        <h6>Summary</h6>
                                        <label>Highlight your unique experiences, ambitions and strengths.</label>
                                        <br />
                                        <Row>
                                            <Col style={{ position: "relative", margin: "0"}}>
                                                <textarea type="text" onChange={(event) => handlePersonalSummaryInputChange(event)} value={personalSummary.summary} rows="8"/>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "2rem" }}>
                                            <Col md={2}>
                                                <button className={GlobalStyles.DarkButton} style={{ display: 'flex', alignItems: 'center'  }} onClick={() => addUserPersonalSummary(db)}>Save</button>
                                            </Col>
                                            <Col style={{ display: 'flex', alignItems: 'center' }} onClick={() => setModalShow((prevValues) => ({...prevValues, personalSummary: false}))}>Cancel</Col>
                                        </Row>
                                    </div>
                                ):
                                modalShow.personalSummaryUpdate ? (
                                    <div className={`${styles.Modal} ${styles.SkillsModal}`} style={{display: modalShow.personalSummaryUpdate ? 'block' : 'none'}}>
                                        <h5>Edit Personal Summary</h5>
                                        <h6>Summary</h6>
                                        <label>Highlight your unique experiences, ambitions and strengths.</label>
                                        <br />
                                        <Row>
                                            <Col style={{ position: "relative", margin: "0"}}>
                                                <textarea type="text" onChange={(event) => handlePersonalSummaryInputChange(event)} value={personalSummary.summary} rows="8"/>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "2rem" }}>
                                            <Col md={2}>
                                                <button className={GlobalStyles.DarkButton} style={{ display: 'flex', alignItems: 'center'  }} onClick={() => addUserPersonalSummary(db)}>Save</button>
                                            </Col>
                                            <Col style={{ display: 'flex', alignItems: 'center' }} onClick={() => setModalShow((prevValues) => ({...prevValues, personalSummaryUpdate: false}))}>Cancel</Col>
                                        </Row>
                                    </div>
                                ):
                                null
                            }
                            <h5>Personal Summary</h5>
                            {resumeData.personalSummary.length === 0 ? 
                                <p>Add a personal summary to your profile as a way to introduce who you are.</p> :
                                <p>{resumeData.personalSummary}</p>
                            }    
                            {resumeData.personalSummary.length > 0 ? 
                                <button className={GlobalStyles.DarkButton} style={{ marginTop: "1rem" }} onClick={() => openUserPersonalSummaryEditModal()}>Edit Summary</button>: 
                                <button className={GlobalStyles.DarkButton} style={{ marginTop: "1rem" }} onClick={() => setModalShow({personalSummary: true})}>Add Summary</button>
                            }
                        </div>
                        <button className={GlobalStyles.BlueButton} style={{ marginTop: "1rem", marginLeft: "1.2rem"}} onClick={() => handleTabClick('personalDetails','personal-details')}>Next</button>
                    </div>

                    <div className={styles.Card} ref={PersonalDetailsRef} style={{ marginBottom: modalShow.personalDetails ? "15rem" : "10rem" }}>
                        <span id="personal-details" className={styles.ScrollMarker}></span>
                        <div className={styles.CardBody}> 
                            <div className={`${styles.Modal} ${styles.PersonalDetailsModal}`} style={{ display: modalShow.personalDetails? "block" : "none" }}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <EditPersonalDetailsFormSuspense user={profile} setModalShow={setModalShow}/>
                                </Suspense>
                            </div>
                            <h5>Personal Details</h5>
                            <Row>
                                <Col xs={4}>
                                    <p>Name</p>
                                </Col>
                                <Col>
                                    <p>{`${resumeData.firstName} ${resumeData.lastName}`}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <p>Phone Number</p>
                                </Col>
                                <Col>
                                    <p>{resumeData.phoneNumber}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <p>Location</p>
                                </Col>
                                <Col>
                                    <p>{resumeData.location}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <p>Email</p>
                                </Col>
                                <Col>
                                    <p>{resumeData.email}</p>
                                </Col>
                            </Row>
                            <Row>
                                <button className={GlobalStyles.DarkButton} style={{ marginTop: "1rem", width: "auto" }} onClick={() => setModalShow({personalDetails: true})}>Edit Personal Details</button>
                            </Row>
                        </div>
                        <button className={GlobalStyles.BlueButton} style={{ marginTop: "1rem", marginLeft: "1.2rem"}} onClick={() => handleTabClick('careerHistory','career-history')}>Next</button>
                    </div>
                    
                    <div className={styles.Card} ref={CareerHistoryRef} style={{ marginBottom: modalShow.careerHistoryUpdate || modalShow.careerHistory ? "18rem" : "10rem" }}>
                        <span id="career-history" className={styles.ScrollMarker}></span>
                        <div className={styles.CardBody}>
                            {modalShow.careerHistory? (
                                <div className={`${styles.Modal} ${styles.CareerHistoryModal}`} style={{display: modalShow.careerHistory ? 'block' : 'none'}}>
                                    <h5>Add Role</h5>
                                    <label>Job Title</label>
                                    <br />
                                    <input type="text" name="title" value={jobRole.title} onChange={(event) => handleCareerHistoryInputChange(event)}/>
                                    <label>Company Name</label>
                                    <br />
                                    <input type="text" name="companyName" value={jobRole.companyName} onChange={(event) => handleCareerHistoryInputChange(event)}/>
                                    <Row>
                                        <Col xs={4}>
                                            <label>Started</label>
                                            <select name="startedMonth" value={jobRole.startedMonth} onChange={(event) => handleCareerHistoryInputChange(event)}>
                                                <option disabled>Month</option>
                                                <option>Jan</option>
                                                <option>Feb</option>
                                                <option>Mar</option>
                                                <option>Apr</option>
                                                <option>May</option>
                                                <option>Jun</option>
                                                <option>Jul</option>
                                                <option>Aug</option>
                                                <option>Sep</option>
                                                <option>Oct</option>
                                                <option>Nov</option>
                                                <option>Dec</option>
                                            </select>
                                        </Col>
                                        <Col xs={4}>
                                        <label></label>
                                            <br/>
                                            <select name="startedYear" value={jobRole.startedYear} onChange={(event) => handleCareerHistoryInputChange(event)}>
                                                <option disabled>Year</option>
                                                {yearsArray.map((year) => (
                                                    <option value={`${year}`}>{year}</option>
                                                ))} 
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <label>Ended</label>
                                            <select name="endedMonth" value={jobRole.endedMonth} onChange={(event) => handleCareerHistoryInputChange(event)}>
                                                <option disabled>Month</option>
                                                <option>Jan</option>
                                                <option>Feb</option>
                                                <option>Mar</option>
                                                <option>Apr</option>
                                                <option>May</option>
                                                <option>Jun</option>
                                                <option>Jul</option>
                                                <option>Aug</option>
                                                <option>Sep</option>
                                                <option>Oct</option>
                                                <option>Nov</option>
                                                <option>Dec</option>
                                            </select>
                                        </Col>
                                        <Col xs={4}>
                                        <label></label>
                                            <br/>
                                            <select name="endedYear" value={jobRole.endedYear} onChange={(event) => handleCareerHistoryInputChange(event)}>
                                                <option disabled>Year</option>
                                                {yearsArray.map((year) => (
                                                    <option value={`${year}`}>{year}</option>
                                                ))} 
                                            </select>
                                        </Col>
                                    </Row>
                                    <label>Description</label>
                                    <br/>
                                    <span>Summarise your responsibilities and achievements.</span>
                                    <textarea rows="8" name="description" onChange={(event) => handleCareerHistoryInputChange(event)}/>
                                    <Row>
                                        <Col md={2}>
                                            <button className={GlobalStyles.DarkButton} style={{ display: 'flex', alignItems: 'center'  }} onClick={() => addUserCareerHistory(db)}>Save</button>
                                        </Col>
                                        <Col style={{ display: 'flex', alignItems: 'center' }} onClick={() => setModalShow((prevValues) => ({...prevValues, careerHistory: false}))}>Cancel</Col>
                                    </Row>
                                </div>
                            ) : 
                            modalShow.careerHistoryUpdate ? (
                                <div className={`${styles.Modal} ${styles.CareerHistoryModal}`} style={{display: modalShow.careerHistoryUpdate ? 'block' : 'none'}}>
                                    <h5>Edit Role</h5>
                                    <label>Job Title</label>
                                    <br />
                                    <input type="text" name="title" value={jobRole.title} onChange={(event) => handleCareerHistoryInputChange(event)}/>
                                    <label>Company Name</label>
                                    <br />
                                    <input type="text" name="companyName" value={jobRole.companyName} onChange={(event) => handleCareerHistoryInputChange(event)}/>                                
                                    <Row>
                                        <Col xs={4}>
                                            <label>Started</label>
                                            <select name="startedMonth" value={jobRole.startedMonth} onChange={(event) => handleCareerHistoryInputChange(event)}>
                                                <option disabled>Month</option>
                                                <option>Jan</option>
                                                <option>Feb</option>
                                                <option>Mar</option>
                                                <option>Apr</option>
                                                <option>May</option>
                                                <option>Jun</option>
                                                <option>Jul</option>
                                                <option>Aug</option>
                                                <option>Sep</option>
                                                <option>Oct</option>
                                                <option>Nov</option>
                                                <option>Dec</option>
                                            </select>
                                        </Col>
                                        <Col xs={4}>
                                        <label></label>
                                            <br/>
                                            <select name="startedYear" value={jobRole.startedYear} onChange={(event) => handleCareerHistoryInputChange(event)}>
                                                <option disabled>Year</option>
                                                {yearsArray.map((year) => (
                                                    <option value={`${year}`}>{year}</option>
                                                ))} 
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <label>Ended</label>
                                            <select name="endedMonth" value={jobRole.endedMonth} onChange={(event) => handleCareerHistoryInputChange(event)}>
                                                <option disabled>Month</option>
                                                <option>Jan</option>
                                                <option>Feb</option>
                                                <option>Mar</option>
                                                <option>Apr</option>
                                                <option>May</option>
                                                <option>Jun</option>
                                                <option>Jul</option>
                                                <option>Aug</option>
                                                <option>Sep</option>
                                                <option>Oct</option>
                                                <option>Nov</option>
                                                <option>Dec</option>
                                            </select>
                                        </Col>
                                        <Col xs={4}>
                                        <label></label>
                                            <br/>
                                            <select name="endedYear" value={jobRole.endedYear} onChange={(event) => handleCareerHistoryInputChange(event)}>
                                                <option disabled>Year</option>
                                                {yearsArray.map((year) => (
                                                    <option value={`${year}`}>{year}</option>
                                                ))} 
                                            </select>
                                        </Col>
                                    </Row>
                                    <label>Description</label>
                                    <br/>
                                    <span>Summarise your responsibilities and achievements.</span>
                                    <textarea rows="8" name="description" value={jobRole.description} onChange={(event) => handleCareerHistoryInputChange(event)}/>
                                    <Row>
                                        <Col md={2}>
                                            <button className={GlobalStyles.DarkButton} style={{ display: 'flex', alignItems: 'center'  }} onClick={() => updateUserCareerHistory(db)}>Save</button>
                                        </Col>
                                        <Col style={{ display: 'flex', alignItems: 'center' }} onClick={() => {setModalShow((prevValues) => ({...prevValues, careerHistoryUpdate: false})); setSearchParams({}); window.location.reload()}}>Cancel</Col>
                                    </Row>
                                </div>
                            ) :
                            null
                            } 
                            <h5>Career History</h5>
                            <p style={{ display: resumeData.careerHistory.length > 0 ? "none" : "block" }}>Let employers know about your previous roles and your experience.</p>
                            {resumeData.careerHistory.map((item, index) => (
                                <Row key={index}>
                                    <Col id={`${item.mapValue.fields.id.stringValue}`}>
                                        <h6>{item.mapValue.fields.jobTitle.stringValue}</h6>
                                        <p>{item.mapValue.fields.companyName.stringValue}</p>
                                        <span>
                                            {`${item.mapValue.fields.startedMonth.stringValue} ${item.mapValue.fields.startedYear.stringValue} - ${item.mapValue.fields.endedMonth.stringValue} ${item.mapValue.fields.endedYear.stringValue}`}
                                        </span>
                                        <br />
                                        <p>{item.mapValue.fields.description.stringValue}</p>
                                        
                                    </Col>
                                    <Col>
                                        <a onClick={() => openUserCareerHistoryEditModal(item)}>Edit</a>
                                    </Col>
                                    <hr />
                                </Row>
                            ))}
                            <button className={GlobalStyles.DarkButton} style={{ marginTop: "1rem" }} onClick={() => setModalShow({careerHistory: true})}>Add Role</button>
                        </div>
                        <button className={GlobalStyles.BlueButton} style={{ marginTop: "1rem", marginLeft: "1.2rem"}} onClick={() => handleTabClick('education','education')}>Next</button>
                    </div>
                    
                    <div className={styles.Card} ref={EducationRef} style={{ marginBottom: modalShow.educationUpdate || modalShow.education ? "15rem" : "10rem" }}>
                        <span id="education" className={styles.ScrollMarker}></span>
                        <div className={styles.CardBody}>
                            {
                                modalShow.education? (
                                    <div className={`${styles.Modal} ${styles.EducationModal}`} style={{display: modalShow.education ? 'block' : 'none'}}>
                                        <h5>Add Education</h5>
                                        <label>Institution</label>
                                        <br />
                                        <input type="text" name="institution" value={educationItem.institution} onChange={(event) => handleEducationInputChange(event)}/>
                                        <label>Course / Qualification</label>
                                        <br />
                                        <input type="text" name="course" value={educationItem.course} onChange={(event) => handleEducationInputChange(event)}/>
                                        <Row>
                                            <Col xs={4}>
                                                <label>Finished (optional)</label>
                                                <select name="finishedYear" value={educationItem.finishedYear} onChange={(event) => handleEducationInputChange(event)}>
                                                    <option disabled>Year</option>
                                                    {yearsArray.map((year) => (
                                                        <option value={`${year}`}>{year}</option>
                                                    ))} 
                                                </select>
                                            </Col>
                                        </Row>
                                        <label>Course highlights (optional)</label>
                                        <br/>
                                        <span>Add activities, projects, awards or achievements during your study.</span>
                                        <textarea rows="8" name="courseHighlights" value={educationItem.courseHighlights} onChange={(event) => handleEducationInputChange(event)}/>
                                        <Row>
                                            <Col md={2}>
                                                <button className={GlobalStyles.DarkButton} style={{ display: 'flex', alignItems: 'center'  }} onClick={() => addUserEducation(db)}>Save</button>
                                            </Col>
                                            <Col style={{ display: 'flex', alignItems: 'center' }} onClick={() => setModalShow((prevValues) => ({...prevValues, education: false}))}>Cancel</Col>
                                        </Row>
                                    </div>
                                ):
                                modalShow.educationUpdate? (
                                    <div className={`${styles.Modal} ${styles.EducationModal}`} style={{display: modalShow.educationUpdate ? 'block' : 'none'}}>
                                        <h5>Edit Education</h5>
                                        <label>Institution</label>
                                        <br />
                                        <input type="text" name="institution" value={educationItem.institution} onChange={(event) => handleEducationInputChange(event)}/>
                                        <label>Course / Qualification</label>
                                        <br />
                                        <input type="text" name="course" value={educationItem.course} onChange={(event) => handleEducationInputChange(event)}/>
                                        <Row>
                                            <Col xs={4}>
                                                <label>Finished (optional)</label>
                                                <select name="finishedYear" value={educationItem.finishedYear} onChange={(event) => handleEducationInputChange(event)}>
                                                    <option disabled>Year</option>
                                                    {yearsArray.map((year) => (
                                                        <option value={`${year}`}>{year}</option>
                                                    ))} 
                                                </select>
                                            </Col>
                                        </Row>
                                        <label>Course highlights (optional)</label>
                                        <br/>
                                        <span>Add activities, projects, awards or achievements during your study.</span>
                                        <textarea rows="8" name="courseHighlights" value={educationItem.courseHighlights} onChange={(event) => handleEducationInputChange(event)}/>
                                        <Row>
                                            <Col md={2}>
                                                <button className={GlobalStyles.DarkButton} style={{ display: 'flex', alignItems: 'center'  }} onClick={() => updateUserEducation(db)}>Save</button>
                                            </Col>
                                            <Col style={{ display: 'flex', alignItems: 'center' }} onClick={() => {setModalShow((prevValues) => ({...prevValues, educationUpdate: false})); setSearchParams({}); window.location.reload()}}>Cancel</Col>
                                        </Row>
                                    </div>
                                ):
                                null
                            }
                            <h5>Education</h5>
                            {resumeData.education.length === 0 ? 
                                <p>Add certificates, degrees and more.</p>:
                                resumeData.education.map((item, index) => (
                                    <Row key={index}>
                                        <Col id={item.mapValue.fields.id.stringValue}>
                                            <h6>{item.mapValue.fields.institution.stringValue}</h6>
                                            <p>{item.mapValue.fields.course.stringValue}</p>
                                            <p>{item.mapValue.fields.finishedYear.stringValue}</p>
                                        </Col>
                                        <Col>
                                            <a onClick={() => openUserEducationHistoryEditModal(item)}>Edit</a>
                                        </Col>
                                        <hr />   
                                    </Row>
                                ))
                            }    
                            <button className={GlobalStyles.DarkButton} style={{ marginTop: "1rem" }} onClick={() => setModalShow({education: true})}>Add Education</button>
                        </div>
                        <button className={GlobalStyles.BlueButton} style={{ marginTop: "1rem", marginLeft: "1.2rem"}} onClick={() => handleTabClick('licenses','licenses')}>Next</button>
                    </div>

                    <div className={styles.Card} ref={LicenseRef} style={{ marginBottom: modalShow.licensesUpdate || modalShow.licenses ? "15rem" : "10rem" }}>
                        <span id="licenses" className={styles.ScrollMarker}></span>
                        <div className={styles.CardBody}>
                            {
                                modalShow.licenses? (
                                    <div className={`${styles.Modal} ${styles.EducationModal}`} style={{display: modalShow.licenses ? 'block' : 'none'}}>
                                        <h5>Add License / Certification</h5>
                                        <label>License name</label>
                                        <br />
                                        <input type="text" name="licenseName" value={licenseItem.licenseName} onChange={(event) => handleLicenseInputChange(event)}/>
                                        <label>Issuing organisation</label>
                                        <br />
                                        <input type="text" name="issuingOrganisation" value={licenseItem.issuingOrganisation} onChange={(event) => handleLicenseInputChange(event)}/>
                                        <Row>
                                            <Col xs={4}>
                                                <label>Issue Date</label>
                                                <select name="issuedMonth" value={licenseItem.issuedMonth} onChange={(event) => handleLicenseInputChange(event)}>
                                                    <option disabled>Month</option>
                                                    <option>Jan</option>
                                                    <option>Feb</option>
                                                    <option>Mar</option>
                                                    <option>Apr</option>
                                                    <option>May</option>
                                                    <option>Jun</option>
                                                    <option>Jul</option>
                                                    <option>Aug</option>
                                                    <option>Sep</option>
                                                    <option>Oct</option>
                                                    <option>Nov</option>
                                                    <option>Dec</option>
                                                </select>
                                            </Col>
                                            <Col xs={4}>
                                            <label></label>
                                                <br/>
                                                <select name="issuedYear" value={licenseItem.issuedYear} onChange={(event) => handleLicenseInputChange(event)}>
                                                    <option disabled>Year</option>
                                                    {yearsArray.map((year) => (
                                                        <option value={`${year}`}>{year}</option>
                                                    ))} 
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <label>Expiry Date</label>
                                                <select name="issuedMonth" value={licenseItem.expiryMonth} onChange={(event) => handleLicenseInputChange(event)}>
                                                    <option disabled>Month</option>
                                                    <option>Jan</option>
                                                    <option>Feb</option>
                                                    <option>Mar</option>
                                                    <option>Apr</option>
                                                    <option>May</option>
                                                    <option>Jun</option>
                                                    <option>Jul</option>
                                                    <option>Aug</option>
                                                    <option>Sep</option>
                                                    <option>Oct</option>
                                                    <option>Nov</option>
                                                    <option>Dec</option>
                                                </select>
                                            </Col>
                                            <Col xs={4}>
                                            <label></label>
                                                <br/>
                                                <select name="issuedYear" value={licenseItem.expiryYear} onChange={(event) => handleLicenseInputChange(event)}>
                                                    <option disabled>Year</option>
                                                    {yearsArray.map((year) => (
                                                        <option value={`${year}`}>{year}</option>
                                                    ))} 
                                                </select>
                                            </Col>
                                        </Row>                                        
                                        <label>Decription (optional)</label>
                                        <br/>
                                        <span>Briefly describe this credential – you can also add a type or URL if applicable.</span>
                                        <textarea rows="8" name="description" value={licenseItem.description} onChange={(event) => handleLicenseInputChange(event)}/>
                                        <Row>
                                            <Col md={2}>
                                                <button className={GlobalStyles.DarkButton} style={{ display: 'flex', alignItems: 'center'  }} onClick={() => addUserLicense(db)}>Save</button>
                                            </Col>
                                            <Col style={{ display: 'flex', alignItems: 'center' }} onClick={() => setModalShow((prevValues) => ({...prevValues, licenses: false}))}>Cancel</Col>
                                        </Row>
                                    </div>
                                ):
                                modalShow.licensesUpdate? (
                                    <div className={`${styles.Modal} ${styles.EducationModal}`} style={{display: modalShow.licensesUpdate ? 'block' : 'none'}}>
                                        <h5>Edit License / Certification</h5>
                                        <label>License name</label>
                                        <br />
                                        <input type="text" name="licenseName" value={licenseItem.licenseName} onChange={(event) => handleLicenseInputChange(event)}/>
                                        <label>Issuing organisation</label>
                                        <br />
                                        <input type="text" name="issuingOrganisation" value={licenseItem.issuingOrganisation} onChange={(event) => handleLicenseInputChange(event)}/>
                                        <Row>
                                            <Col xs={4}>
                                                <label>Issue Date</label>
                                                <select name="issuedMonth" value={licenseItem.issuedMonth} onChange={(event) => handleLicenseInputChange(event)}>
                                                    <option disabled>Month</option>
                                                    <option>Jan</option>
                                                    <option>Feb</option>
                                                    <option>Mar</option>
                                                    <option>Apr</option>
                                                    <option>May</option>
                                                    <option>Jun</option>
                                                    <option>Jul</option>
                                                    <option>Aug</option>
                                                    <option>Sep</option>
                                                    <option>Oct</option>
                                                    <option>Nov</option>
                                                    <option>Dec</option>
                                                </select>
                                            </Col>
                                            <Col xs={4}>
                                            <label></label>
                                                <br/>
                                                <select name="issuedYear" value={licenseItem.issuedYear} onChange={(event) => handleLicenseInputChange(event)}>
                                                    <option disabled>Year</option>
                                                    {yearsArray.map((year) => (
                                                        <option value={`${year}`}>{year}</option>
                                                    ))} 
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <label>Expiry Date</label>
                                                <select name="issuedMonth" value={licenseItem.expiryMonth} onChange={(event) => handleLicenseInputChange(event)}>
                                                    <option disabled>Month</option>
                                                    <option>Jan</option>
                                                    <option>Feb</option>
                                                    <option>Mar</option>
                                                    <option>Apr</option>
                                                    <option>May</option>
                                                    <option>Jun</option>
                                                    <option>Jul</option>
                                                    <option>Aug</option>
                                                    <option>Sep</option>
                                                    <option>Oct</option>
                                                    <option>Nov</option>
                                                    <option>Dec</option>
                                                </select>
                                            </Col>
                                            <Col xs={4}>
                                            <label></label>
                                                <br/>
                                                <select name="issuedYear" value={licenseItem.expiryYear} onChange={(event) => handleLicenseInputChange(event)}>
                                                    <option disabled>Year</option>
                                                    {yearsArray.map((year) => (
                                                        <option value={`${year}`}>{year}</option>
                                                    ))} 
                                                </select>
                                            </Col>
                                        </Row>                                        
                                        <label>Decription (optional)</label>
                                        <br/>
                                        <span>Briefly describe this credential – you can also add a type or URL if applicable.</span>
                                        <textarea rows="8" name="description" value={licenseItem.description} onChange={(event) => handleLicenseInputChange(event)}/>                                        
                                        <Row>
                                            <Col md={2}>
                                                <button className={GlobalStyles.DarkButton} style={{ display: 'flex', alignItems: 'center'  }} onClick={() => updateUserLicense(db)}>Save</button>
                                            </Col>
                                            <Col style={{ display: 'flex', alignItems: 'center' }} onClick={() => {setModalShow((prevValues) => ({...prevValues, licensesUpdate: false})); setSearchParams({}); window.location.reload()}}>Cancel</Col>
                                        </Row>
                                    </div>
                                ):
                                null
                            }
                            <h5>Licenses & Certifications</h5>
                            {resumeData.licenses.length === 0 ? 
                                <p>Showcase your professional credentials. Add your relevant licences, certificates, memberships and accreditations here.</p>:
                                resumeData.licenses.map((item, index) => (
                                    <Row key={index}>
                                        <Col id={item.mapValue.fields.id.stringValue}>
                                            <h6>{item.mapValue.fields.licenseName.stringValue}</h6>
                                            <p>{item.mapValue.fields.issuingOrganisation.stringValue}</p>
                                            <p>{`${item.mapValue.fields.issuedMonth.stringValue} ${item.mapValue.fields.issuedYear.stringValue} - ${item.mapValue.fields.expiryMonth.stringValue} ${item.mapValue.fields.expiryYear.stringValue}`}</p>
                                            <p>{item.mapValue.fields.description.stringValue}</p>
                                        </Col>
                                        <Col>
                                            <a onClick={() => openUserLicenseEditModal(item)}>Edit</a>
                                        </Col>
                                        <hr />   
                                    </Row>
                                ))
                            }    
                            <button className={GlobalStyles.DarkButton} style={{ marginTop: "1rem" }} onClick={() => setModalShow({licenses: true})}>Add License / Certification</button>
                        </div>
                        <button className={GlobalStyles.BlueButton} style={{ marginTop: "1rem", marginLeft: "1.2rem"}} onClick={() => handleTabClick('skills','skills')}>Next</button>
                    </div> 

                    <div className={styles.Card} ref={skillsRef}>
                        <span id="skills" className={styles.ScrollMarker}></span>
                        <div className={styles.CardBody}>
                            {
                                modalShow.skills? (
                                    <div className={`${styles.Modal} ${styles.SkillsModal}`} style={{display: modalShow.skills ? 'block' : 'none'}}>
                                        <h5>Add Skills</h5>
                                        <label>Add new skill</label>
                                        <br />
                                        <Row>
                                            <Col style={{ position: "relative", margin: "0"}}>
                                                <input type="text" onChange={(event) => handleSkillsInputChange(event)} value={skillsItem.skillName}/>
                                                <aside className={styles.DropdownList} style={{ borderTop: showList.skills && skillsItem.skillName.length !== 0 ? "1px solid" : "none" }}>
                                                    {skillsArray.map((term, index) => (
                                                        showList.skills && skillsItem.skillName.length !== 0 && term.toLowerCase().includes(skillsItem.skillName.toLowerCase()) ? <div className={styles.DropListItem} key={index} onClick={(event) => handleResultClick(event, 'skill', term)}>{term}</div> : null
                                                    ))}
                                                </aside>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "2rem" }}>
                                            <Col md={2}>
                                                <button className={GlobalStyles.DarkButton} style={{ display: 'flex', alignItems: 'center'  }} onClick={() => addUserSkill(db)}>Save</button>
                                            </Col>
                                            <Col style={{ display: 'flex', alignItems: 'center' }} onClick={() => setModalShow((prevValues) => ({...prevValues, skills: false}))}>Cancel</Col>
                                        </Row>
                                    </div>
                                ):
                                null
                            }
                            <h5>Skills</h5>
                            {resumeData.skills.length === 0 ? 
                                <p>Let employers know how valuable you can be to them.</p>:
                                <Row>
                                    {resumeData.skills.map((item) => (
                                        <Col sm={6}>
                                            <p className={styles.Skill}>{item.stringValue} <img src="https://i3.lensdump.com/i/tEG85k.png" width="10px" onClick={() => deleteUserSkill(db, item.stringValue)}/></p>
                                        </Col> 
                                    ))}
                                </Row>
                            }    
                            <button className={GlobalStyles.DarkButton} style={{ marginTop: "1rem" }} onClick={() => setModalShow({skills: true})}>Add Skills</button>
                        </div>
                        <button className={GlobalStyles.BlueButton} style={{ marginTop: "1rem", marginLeft: "1.2rem"}} onClick={() => handleTabClick('languages','languages')}>Next</button>
                    </div>

                    <div className={styles.Card} ref={languagesRef} style={{ marginBottom: modalShow.languagesUpdate || modalShow.languages ? "15rem" : "10rem" }}>
                        <span id="languages" className={styles.ScrollMarker}></span>
                        <div className={styles.CardBody}>
                            {
                                modalShow.languages? (
                                    <div className={`${styles.Modal} ${styles.SkillsModal}`} style={{display: modalShow.languages ? 'block' : 'none'}}>
                                        <h5>Add Language</h5>
                                        <label>Language</label>
                                        <br />
                                        <Row>
                                            <Col style={{ position: "relative", margin: "0"}}>
                                                <input type="text" onChange={(event) => handleLanguagesInputChange(event)} value={languageItem.languageName}/>
                                                <aside className={styles.DropdownList} style={{ borderTop: showList.languages && languageItem.languageName.length !== 0 ? "1px solid" : "none" }}>
                                                    {languagesArray.map((term, index) => (
                                                        showList.languages && languageItem.languageName.length !== 0 && term.toLowerCase().includes(languageItem.languageName.toLowerCase()) ? <div className={styles.DropListItem} key={index} onClick={(event) => handleResultClick(event, 'language', term)}>{term}</div> : null
                                                    ))}
                                                </aside>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "2rem" }}>
                                            <Col md={2}>
                                                <button className={GlobalStyles.DarkButton} style={{ display: 'flex', alignItems: 'center'  }} onClick={() => addUserLanguage(db)}>Save</button>
                                            </Col>
                                            <Col style={{ display: 'flex', alignItems: 'center' }} onClick={() => setModalShow((prevValues) => ({...prevValues, languages: false}))}>Cancel</Col>
                                        </Row>
                                    </div>
                                ):
                                null
                            }
                            <h5>Languages</h5>
                            {resumeData.languages.length === 0 ? 
                                <p>Add languages to appeal to more companies and employers.</p>:
                                <Row>
                                    {resumeData.languages.map((item) => (
                                        <Col sm={6}>
                                            <p className={styles.Skill}>{item.stringValue} <img src="https://i3.lensdump.com/i/tEG85k.png" width="10px" onClick={() => deleteUserLanguage(db, item.stringValue)}/></p>
                                        </Col> 
                                    ))}
                                </Row>
                            }    
                            <button className={GlobalStyles.DarkButton} style={{ marginTop: "1rem" }} onClick={() => setModalShow({languages: true})}>Add Language</button>
                        </div>
                    </div>                        

                    {/* <span id="video" className={styles.ScrollMarker}></span> */}

                </Container>  
            </div>
        </div>
    )
};

export default CreateResume;