import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './Navbar.module.css';

import { useState, useEffect } from 'react';

import { Link, NavLink, useLocation, useSearchParams, Navigate } from 'react-router-dom';

import { Cross as Hamburger } from 'hamburger-react'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const auth = getAuth();

let activeUser = null;

function AuthorizedNavbar() {
  
  const location = useLocation();

  const [dropdownShow, setDropdownShow] = useState(false);

  const [hover, setHover] = useState({});

  function handleSignOut() {
    signOut(auth).then(() => {
      // Sign-out successful.
      window.location.replace('/')
      ;
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <Container className={styles.Navbar}>
      <Row>
          <Col md={2}><a href="/" className={styles.Logo}>60 Seconds Resume</a></Col>
          <Col md={6}>
              <a style={{fontWeight: location.pathname === "/" ? "700" : "400" }} onMouseEnter={() => setHover({"Jobs" : true})} onMouseLeave={() => setHover({})}>
              Jobs <div className={GlobalStyles.Underline} style={{ width: hover.Jobs || location.pathname === "/" ? "100%" : "0" }}></div>
              </a>
              <a onMouseEnter={() => setHover({"CompanyReviews" : true})} onMouseLeave={() => setHover({})}>
              Company Reviews <div className={GlobalStyles.Underline} style={{ width: hover.CompanyReviews? "100%" : "0" }}></div>
              </a>
          </Col>
          <Col md={4} style={{ position: "relative" }}>
            <div className={styles.ProfileDropdown} style={{ display: dropdownShow? "block" : 'none' }}>
              <a>Saved Searches</a>
              <Link to="/activity/saved-jobs">Saved Jobs</Link>
              <a>Recommended Jobs</a>
              <hr style={{ margin: "0", padding: "0" }}/>
              <a>Settings</a>
              <a onClick={handleSignOut}>Sign Out</a>
            </div> 
            <Row style={{ display: "flex", justifyContent: "end" }}>
              <Col xs={3} s={1}>
                <div onClick={() => setDropdownShow(dropdownShow ? false : true)}>
                  <img src="https://i1.lensdump.com/i/tEudrk.png" width="24px"/>
                </div>
              </Col>
              <Col style={{ padding: "0", margin: "0"}}>
                <a onMouseEnter={() => setHover({"EmployersLogIn" : true})} onMouseLeave={() => setHover({})}>
                  Employer Log In <div className={GlobalStyles.Underline} style={{ width: hover.EmployersLogIn? "100%" : "0" }}></div>
                </a>
              </Col>
            </Row>
          </Col>
      </Row>
    </Container>
  )
};

function UnauthorizedNavbar() {

  const location = useLocation();

  const [dropdownShow, setDropdownShow] = useState(false);

  const [hover, setHover] = useState({});

  function handleSignOut() {
    signOut(auth).then(() => {
      // Sign-out successful.
      window.location.replace('/')
      ;
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <Container className={styles.Navbar}>
      <Row>
          <Col md={2}><a href="/" className={styles.Logo}>60 Seconds Resume</a></Col>
          <Col md={6}>
              <a style={{fontWeight: location.pathname === "/" ? "700" : "400" }} onMouseEnter={() => setHover({"Jobs" : true})} onMouseLeave={() => setHover({})}>
              Jobs <div className={GlobalStyles.Underline} style={{ width: hover.Jobs || location.pathname === "/" ? "100%" : "0" }}></div>
              </a>
              <a onMouseEnter={() => setHover({"CompanyReviews" : true})} onMouseLeave={() => setHover({})}>
              Company Reviews <div className={GlobalStyles.Underline} style={{ width: hover.CompanyReviews? "100%" : "0" }}></div>
              </a>
          </Col>
          <Col md={4} style={{ position: "relative" }}>
            <div className={styles.ProfileDropdown} style={{ display: dropdownShow? "block" : 'none' }}>
              <a>Saved Searches</a>
              <Link to="/activity/saved-jobs">Saved Jobs</Link>
              <a>Recommended Jobs</a>
              <hr style={{ margin: "0", padding: "0" }}/>
              <a>Settings</a>
              <a onClick={handleSignOut}>Sign Out</a>
            </div> 
            <Row style={{ display: "flex", justifyContent: "end" }}>
              <Col xs={3} s={1}>
                {/* {auth.currentUser ? (
                  <div onClick={() => setDropdownShow(dropdownShow ? false : true)}>
                    <img src="https://i1.lensdump.com/i/tEudrk.png" width="24px"/>
                  </div>
                ) :  */}
                <Link to="/login" 
                  style={{ color: "#3B4DE4", fontWeight: "700", textAlign: "center" }}                     
                  onMouseEnter={() => setHover({"SignIn" : true})}
                  onMouseLeave={() => setHover({})}>
                  Sign In <div className={GlobalStyles.Underline} style={{ width: hover.SignIn? "100%" : "0"}}></div>
                </Link>
              </Col>
              <Col style={{ padding: "0", margin: "0"}}>
                <a onMouseEnter={() => setHover({"EmployersLogIn" : true})} onMouseLeave={() => setHover({})}>
                  Employer Log In <div className={GlobalStyles.Underline} style={{ width: hover.EmployersLogIn? "100%" : "0" }}></div>
                </a>
              </Col>
            </Row>
          </Col>
      </Row>
    </Container>
  )
};

function Navbar() {

  const location = useLocation();

  const [dropdownShow, setDropdownShow] = useState(false);

  const [hover, setHover] = useState({});

  const [show, setShow] = useState(false);

  function handleClick () {
    if (show) {
      enableBodyScroll(document);
    } else {
      disableBodyScroll(document);
    }
  };

  function handleCloseModal () {
    setShow(false);
  }

  function handleSignOut() {
    signOut(auth).then(() => {
      // Sign-out successful.
      window.location.replace('/')
      ;
    }).catch((error) => {
      // An error happened.
    });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            activeUser = {...user};          
        } else {
            activeUser = null;
        }
    })
  }, [auth]);

  return (
        <div className={styles.NavbarContainer}>
          <Container className={styles.Navbar}>
            <Row>
                <Col md={2}><a href="/" className={styles.Logo}>60 Seconds Resume</a></Col>
                <Col md={6}>
                    <a style={{fontWeight: location.pathname === "/" ? "700" : "400" }} onMouseEnter={() => setHover({"Jobs" : true})} onMouseLeave={() => setHover({})}>
                    Jobs <div className={GlobalStyles.Underline} style={{ width: hover.Jobs || location.pathname === "/" ? "100%" : "0" }}></div>
                    </a>
                    <a onMouseEnter={() => setHover({"CompanyReviews" : true})} onMouseLeave={() => setHover({})}>
                    Company Reviews <div className={GlobalStyles.Underline} style={{ width: hover.CompanyReviews? "100%" : "0" }}></div>
                    </a>
                </Col>
                <Col md={4} style={{ position: "relative" }}>
                  <div className={styles.ProfileDropdown} style={{ display: dropdownShow? "block" : 'none' }}>
                    <a href="/dashboard">Profile</a>
                    <Link to="activity/saved-jobs">Saved Jobs</Link>
                    <hr style={{ margin: "0", padding: "0" }}/>
                    <Link to="/settings">Settings</Link>
                    <a onClick={handleSignOut}>Sign Out</a>
                  </div> 
                  <Row style={{ display: "flex", justifyContent: "end" }}>
                    <Col xs={3} s={1}>
                      {auth.currentUser ? (
                        <div onClick={() => setDropdownShow(dropdownShow ? false : true)}>
                          <img src="https://i1.lensdump.com/i/tEudrk.png" width="24px"/>
                        </div>
                      ) : 
                      <Link to="/login" 
                        style={{ color: "#3B4DE4", fontWeight: "700", textAlign: "center" }}                     
                        onMouseEnter={() => setHover({"SignIn" : true})}
                        onMouseLeave={() => setHover({})}>
                        Sign In <div className={GlobalStyles.Underline} style={{ width: hover.SignIn? "100%" : "0"}}></div>
                      </Link> }
                    </Col>
                    <Col style={{ padding: "0", margin: "0"}}>
                      <a onMouseEnter={() => setHover({"EmployersLogIn" : true})} onMouseLeave={() => setHover({})}>
                        Employer Log In <div className={GlobalStyles.Underline} style={{ width: hover.EmployersLogIn? "100%" : "0" }}></div>
                      </a>
                    </Col>
                  </Row>
                </Col>
            </Row>
          </Container>          
          <Container className={styles.NavbarMobile}>
            {/* {show ? <img src="https://i3.lensdump.com/i/ruRYP3.png" width="42px" onClick={handleClick}/> : <img src="https://i.lensdump.com/i/rLnpNH.png" width="42px" onClick={handleClick}/>} */}
            <div onClick={handleClick}>
              <Hamburger toggled={show} toggle={setShow}/>
            </div>
            <Link to="/" className={`${styles.NavItem} ${styles.Logo}`} onClick={handleCloseModal}>60 Seconds Resume</Link>
          </Container>
          <div className={styles.NavbarMobileModalContainer}>
            <div className={styles.NavbarMobileModal} style={{ display: show? 'flex' : 'none' }}>
              <a href="/" className={styles.NavItem} onClick={handleClick}>Jobs</a>
              <Link to="employers" className={styles.NavItem} onClick={handleClick}>For Employers</Link>
              <a href="/dashboard">Profile</a>
              <a onClick={handleSignOut}>Sign Out</a>
            </div>
          </div>
        </div>
    );
}

export default Navbar;