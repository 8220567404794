import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './JobApply.module.css';

import { useState, useEffect } from 'react';

import { useParams, Link } from 'react-router-dom';

import { useInView } from 'react-intersection-observer';

import {collection, getDocs } from 'firebase/firestore/lite';
import {app, db} from '../../util/firebase';
import { getAuth, onAuthStateChanged, updateProfile } from "firebase/auth";

import _ from 'lodash';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

let activeUser;

const auth = getAuth();

function JobApply() {

    const params = useParams();

    const [profile, setProfile] = useState({});

    const [job, setJob] = useState({});

    const [resumeData, setResumeData] = useState({
        firstName: '',
        lastName: '',
        location: '',
        phoneNumber: '',
        email: '',
        careerHistory: [],
        education: [],
        licenses: [],
        skills: [],
        languages: [],
        personalSummary: ''
    });

    const [tab, setTab] = useState({chooseDocuments: true});

    const [coverLetterOption, setCoverLetterOption] = useState({
        upload: false,
        write: false,
        skip: true
    })

    const [resumeOptions, setResumeOptions] = useState({
        upload: false,
        profile: false
    });

    const [resumeUpload, setResumeUpload] = useState("");
    const [coverLetterUpload, setCoverLetterUpload] = useState("");

    const [applicantAnswers, setApplicantAnswers] = useState({});

    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        getJobs(db)
        .then((jobs) => {
            setJob({...jobs[0]._document.data.value.mapValue.fields});
        })
    }, [setJob])

    async function getJobs(db) {
        const jobsCol = collection(db, 'jobs');
        const jobsSnapshot = await getDocs(jobsCol);
        const jobsData = jobsSnapshot.docs.filter(doc => doc.id === params.jobId);
        return jobsData;
    };

    function handleTabClick(elementName, elementId) {
        setTab({[elementName]: true})
        document.getElementById(elementId).scrollIntoView();
    };


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                activeUser = {...user};
                setProfile({...user});               
            } else {
        
            }
        })
    }, [auth]);

    useEffect(() => {
        getUsers(db)
        .then((data) => {
            const {firstName, lastName, location, phoneNumber, email, careerHistory, education, licenses, skills, languages, personalSummary} = data[0]._document.data.value.mapValue.fields;
            setResumeData((prevValues) => {
                return {
                    ...prevValues,
                    firstName: firstName.stringValue,
                    lastName: lastName.stringValue,
                    location: location.stringValue,
                    phoneNumber: phoneNumber.stringValue,
                    email: email.stringValue,
                    careerHistory: careerHistory.arrayValue.values && careerHistory.arrayValue.values.length > 0 ? careerHistory.arrayValue.values : [] ,
                    education: education.arrayValue.values && education.arrayValue.values.length > 0 ? education.arrayValue.values : [],
                    licenses: licenses.arrayValue.values && licenses.arrayValue.values.length > 0 ? licenses.arrayValue.values : [],
                    skills: skills.arrayValue.values && skills.arrayValue.values.length > 0 ? skills.arrayValue.values : [],
                    languages: languages.arrayValue.values && languages.arrayValue.values.length > 0 ? languages.arrayValue.values : [],
                    personalSummary: personalSummary.stringValue
                }
            })
        })
    }, [setResumeData]);

    async function getUsers(db) {
        const usersCol = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCol);
        const userData = usersSnapshot.docs.filter(doc => doc.id === activeUser.uid);
        // const userData = usersList[0]._document.data.value.mapValue.fields;
        return userData;
    };

    function auto_grow() {
        let element = document.getElementById("cover-letter");
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";
    }

    function handleSelectChange(event, question) {
        const {value} = event.target;
        setApplicantAnswers((prevValues) => {
            return {
                ...prevValues,
                [question]: value
            }
        })
    };

    function validateDocumentsUpload() {
        if (resumeUpload.length > 0 || resumeOptions.profile) {
            handleTabClick('employerQuestions','employer-questions');
            setFormErrors({});
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            setFormErrors((prevValues) => {
                return {
                    ...prevValues,
                    resume: "Please select a resume option"
                }
            });
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    };

    function validateApplicantAnswers() {
        if (_.isEmpty(applicantAnswers)) {
            setFormErrors((prevValues) => {
                return {
                    ...prevValues,
                    employerQuestions: "Please answer all questions"
                }
            });
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            const allAnswered = Object.values(applicantAnswers).length === job.employerQuestions.arrayValue.values.length && Object.values(applicantAnswers).every(value => value.length > 0);
            if (allAnswered) {
                handleTabClick('review','review');
                setFormErrors({});
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            } else {
                setFormErrors((prevValues) => {
                    return {
                        ...prevValues,
                        employerQuestions: "Please answer all questions"
                    }
                });
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }
    };

    function handleResumeUpload() {
        let fileName = document.getElementById("resume-upload-btn").files[0].name;
        document.getElementById("resume-file-chosen").innerText = fileName + " uploaded";
        document.getElementById("upload-another-resume").innerText = "Upload a different resume";
        setResumeUpload(fileName);
    };

    function handleCoverLetterUpload() {
        let fileName = document.getElementById("cover-letter-upload-btn").files[0].name;
        document.getElementById("cover-letter-file-chosen").innerText = fileName + " uploaded";
        document.getElementById("upload-another-cover-letter").innerText = "Upload a different cover letter"
        setCoverLetterUpload(fileName);
    };

    return (
        <div className={styles.JobApply}>
            <Container style={{display: "flex", justifyContent: "center"}}>
                <Row style={{width: "70%", textAlign: "center", padding: "2rem 0"}}>
                    {/* <Col xs={6}>LOGO</Col> */}
                    <Col>
                        <span>Applying for</span>
                        {job.position ? 
                            <>
                                <h3>{job.position.stringValue}</h3>
                                <span>{job.employer.stringValue}</span>
                            </>:
                            null
                        }
                    </Col>
                </Row>
            </Container>
            <Container className={styles.TabMenu} fluid>
                    <nav>
                        <div style={{display: 'flex', }}>
                            <div>
                                <a>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>
                                            {
                                                [1,2,3,4,5,6,7,8,9,10,11,12].map((square) => (
                                                    <img src="https://i3.lensdump.com/i/tdZoei.png" width="3px" style={{ margin: "0 1px" }}/>
                                                ))
                                            }
                                        </span>
                                        {tab.chooseDocuments ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>
                                            {
                                                [1,2,3,4,5,6,7,8,9,10,11,12].map((square) => (
                                                    <img src="https://i3.lensdump.com/i/tdZoei.png" width="3px" style={{ margin: "0 1px" }}/>
                                                ))
                                            }
                                        </span>
                                    </div>
                                    <h6>Choose <br/>Documents</h6>
                                </a>
                            </div>
                            <div>
                                <a>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>
                                            {
                                                [1,2,3,4,5,6,7,8,9,10,11,12].map((square) => (
                                                    <img src="https://i3.lensdump.com/i/tdZoei.png" width="3px" style={{ margin: "0 1px" }}/>
                                                ))
                                            }
                                        </span>
                                        {tab.employerQuestions ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>
                                            {
                                                [1,2,3,4,5,6,7,8,9,10,11,12].map((square) => (
                                                    <img src="https://i3.lensdump.com/i/tdZoei.png" width="3px" style={{ margin: "0 1px" }}/>
                                                ))
                                            }
                                        </span>
                                    </div>
                                    <h6>Employer <br/> Questions</h6>
                                </a>
                            </div>
                            <div>
                                <a>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>
                                            {
                                                [1,2,3,4,5,6,7,8,9,10,11,12].map((square) => (
                                                    <img src="https://i3.lensdump.com/i/tdZoei.png" width="3px" style={{ margin: "0 1px" }}/>
                                                ))
                                            }
                                        </span>
                                        {tab.review ? <img src='https://i.imgur.com/zle2Knn.png' width='24px'/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px'/> }
                                        <span>
                                            {
                                                [1,2,3,4,5,6,7,8,9,10,11,12].map((square) => (
                                                    <img src="https://i3.lensdump.com/i/tdZoei.png" width="3px" style={{ margin: "0 1px" }}/>
                                                ))
                                            }
                                        </span>
                                    </div>
                                    <h6>Review & <br/>Submit</h6>
                                </a>
                            </div>
                        </div>
                    </nav>
            </Container>
            <div className={styles.Body}>
                <Container className={styles.FormError} style={{display: _.isEmpty(formErrors) ? "none" : "block"}}>
                    <Row>
                        <Col xs={1}>
                            <img src="https://i.imgur.com/9XWhhz2.png" width="24px"/>
                        </Col>
                        <Col>
                            <span>Before you continue with your application please address the following issues:</span>
                            <ul>
                              {formErrors.resume ? <li>{formErrors.resume}</li>: null}
                              {formErrors.employerQuestions ? <li>{formErrors.employerQuestions}</li>: null}
                            </ul>
                        </Col>
                    </Row>
                </Container>

                <div className={styles.Card} id="choose-documents" style={{ display: tab.chooseDocuments ? "block" : "none" }}>
                    <div className={styles.CardBody}>
                        <h5>Resume</h5>
                        <div onClick={() => setResumeOptions({upload: resumeOptions.upload ? false : true})}>
                            {resumeOptions.upload? <img src='https://i.imgur.com/zle2Knn.png' width='24px' style={{marginRight: "0.5rem"}}/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px' style={{marginRight: "0.5rem"}}/>}<span>Upload resume</span>
                        </div>
                        <div style={{padding: "1rem 0", display: resumeOptions.upload ? "block" : "none"}}>
                            <input type="file" id="resume-upload-btn" hidden onChange={handleResumeUpload}/>
                            <p>Accepted file types: .doc, .docx, .pdf, .txt and .rtf (2MB limit).</p>
                            <button onClick={() => {document.getElementById("resume-upload-btn").click()}} className={GlobalStyles.DarkButton} style={{display: resumeUpload.length > 0? "none" : "block"}}>Browse</button>
                            <p id="resume-file-chosen" style={{ margin: "1rem 0" }}></p>
                            <span style={{color: "#3A4CE4"}} id="upload-another-resume" onClick={() => {document.getElementById("resume-upload-btn").click()}}></span>
                        </div>
                        <br/>
                        <div onClick={() => setResumeOptions({profile: resumeOptions.profile ? false : true})}>
                            {resumeOptions.profile? <img src='https://i.imgur.com/zle2Knn.png' width='24px' style={{marginRight: "0.5rem"}}/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px' style={{marginRight: "0.5rem"}}/>}<span>Apply with 60 Seconds Resume Profile</span>
                        </div>
                        <div className={styles.ExistingResume} style={{ border: resumeOptions.profile ? "1px solid #3A4CE4" : "1px solid rgb(181, 180, 180)"}}>
                            <h5>{resumeData.firstName} {resumeData.lastName}</h5>
                            <span>{resumeData.email}</span>
                            <br />
                            <span>{resumeData.phoneNumber}</span>
                            <p>{resumeData.personalSummary}</p>
                            <span style={{color: "#3A4CE4"}}>Edit profile</span>
                        </div>
                    </div>

                    <div className={`${styles.CardBody} ${styles.CoverLetter}`} style={{ marginTop: "2rem" }}>
                        <h5>Cover letter</h5>
                        <div onClick={() => setCoverLetterOption({upload: coverLetterOption.upload ? false : true})}>
                            {coverLetterOption.upload? <img src='https://i.imgur.com/zle2Knn.png' width='24px' style={{marginRight: "0.5rem"}}/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px' style={{marginRight: "0.5rem"}}/>}<span>Upload a cover letter</span>
                        </div>
                        <div style={{padding: "1rem 0", display: coverLetterOption.upload ? "block" : "none"}}>
                            <input type="file" id="cover-letter-upload-btn" hidden onChange={handleCoverLetterUpload}/>
                            <p>Accepted file types: .doc, .docx, .pdf, .txt and .rtf (2MB limit).</p>
                            <button onClick={() => {document.getElementById("cover-letter-upload-btn").click()}} className={GlobalStyles.DarkButton} style={{display: coverLetterUpload.length > 0? "none" : "block"}}>Browse</button>
                            <p id="cover-letter-file-chosen" style={{ margin: "1rem 0" }}></p>
                            <span style={{color: "#3A4CE4"}} id="upload-another-cover-letter" onClick={() => {document.getElementById("cover-letter-upload-btn").click()}}></span>
                        </div>
                        <br/>
                        <div onClick={() => setCoverLetterOption({write: coverLetterOption.write ? false : true})}>
                            {coverLetterOption.write? <img src='https://i.imgur.com/zle2Knn.png' width='24px' style={{marginRight: "0.5rem"}}/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px' style={{marginRight: "0.5rem"}}/>}<span>Write a cover letter</span>
                        </div>
                        <textarea id="cover-letter" onInput={() => auto_grow()} style={{display: coverLetterOption.write ? "block" : "none"}}/>
                        <br/>
                        <div onClick={() => setCoverLetterOption({skip: coverLetterOption.skip ? false : true})}>
                            {coverLetterOption.skip? <img src='https://i.imgur.com/zle2Knn.png' width='24px' style={{marginRight: "0.5rem"}}/> : <img src='https://i.imgur.com/dF2rsPd.png' width='24px' style={{marginRight: "0.5rem"}}/>}<span>Don't include a cover letter</span>
                        </div>
                    </div>
                    <Row className={styles.CardFooter}>
                        <Col as={"div"}>
                            {/* <button style={{backgroundColor: "transparent", border: "none"}}>Back</button> */}
                        </Col>
                        <Col as={"div"}>
                            <button className={GlobalStyles.BlueButton} onClick={validateDocumentsUpload}>Next</button> 
                        </Col>
                    </Row>  
                </div> 

                <div className={styles.Card} id="employer-questions" style={{ display: tab.employerQuestions ? "block" : "none" }}>
                  <div className={`${styles.CardBody} ${styles.EmployerQuestions}`}>
                      <h5>Employer Questions</h5>
                        {
                            job.employerQuestions?
                                job.employerQuestions.arrayValue.values? (
                                job.employerQuestions.arrayValue.values.map((question, index) => (
                                    <div key={index}>
                                        <label>{question.mapValue.fields.question.stringValue}</label>
                                        <br/>
                                        <select onChange={(event) => handleSelectChange(event, question.mapValue.fields.question.stringValue)}>
                                            <option value=""> </option>
                                            {
                                                question.mapValue.fields.answers.arrayValue?
                                                    question.mapValue.fields.answers.arrayValue.values.map((answer, index) => (
                                                        <option key={index} value={answer.stringValue}>{answer.stringValue}</option>
                                                    ))
                                                : null
                                            }
                                        </select>
                                    </div>
                                ))
                                ): null
                            :null
                        }
                    </div>
                    <Row className={styles.CardFooter}>
                        <Col as={"div"}>
                            <button style={{backgroundColor: "transparent", border: "none"}} onClick={() => {handleTabClick('chooseDocuments', 'choose-documents'); setFormErrors({})}}>Back</button>
                        </Col>
                        <Col as={"div"}>
                            <button className={GlobalStyles.BlueButton} onClick={() => validateApplicantAnswers()}>Next</button> 
                        </Col>
                    </Row> 
                </div>

                <div className={styles.Card} id="review" style={{ display: tab.review ? "block" : "none" }}>
                    <div className={styles.CardBody}>
                        <Row>
                            <Col xs={10}><h5>Personal Details</h5></Col>
                            <Col>
                                <a onClick={() => {handleTabClick('chooseDocuments', 'choose-documents')}}>Edit</a>
                            </Col>
                        </Row>
                        <h6>{`${resumeData.firstName} ${resumeData.lastName}`}</h6>
                        <span>{resumeData.phoneNumber}</span>
                        <br />
                        <span>{resumeData.email}</span>
                    </div>
                    <div className={styles.CardBody} style={{ marginTop: "2rem" }}>
                        <Row>
                            <Col xs={10}><h5>Resume & Cover Letter</h5></Col>
                            <Col>
                                <a onClick={() => {handleTabClick('chooseDocuments', 'choose-documents')}}>Edit</a>
                            </Col>
                        </Row>
                        <ul>
                            <li>{resumeOptions.profile ? "Applying with profile" : resumeOptions.upload? resumeUpload : ""}</li>
                            <li>{coverLetterOption.skip ? "No cover letter added" : coverLetterOption.write ? "You wrote a cover letter for this application" : coverLetterOption.upload ? coverLetterUpload : ""}</li>
                        </ul>
                    </div>
                    <div className={styles.CardBody} style={{ marginTop: "2rem" }}>
                        <Row>
                            <Col xs={10}><h5>Employer Questions</h5></Col>
                            <Col>
                                <a onClick={() => {handleTabClick('employerQuestions', 'employer-questions')}}>Edit</a>
                            </Col>
                        </Row>
                        <span>{`You answered ${Object.keys(applicantAnswers).length} of ${ job.employerQuestions ? job.employerQuestions.arrayValue.values.length : 0} questions`}</span>
                    </div>
                    <Row className={styles.CardFooter}>
                        <Col as={"div"}>
                            <button style={{backgroundColor: "transparent", border: "none"}} onClick={() => {handleTabClick('employerQuestions','employer-questions'); setFormErrors({})}}>Back</button>
                        </Col>
                        <Col as={"div"}>
                        <button className={GlobalStyles.BlueButton} style={{ marginTop: "1rem", marginLeft: "1.2rem"}}>Submit Application</button> 
                        </Col>
                    </Row> 
                </div>
            </div>
        </div>
    )
};

export default JobApply;