import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './VideoRecorder.module.css';

import { useState } from 'react';

import { useTimer } from 'react-timer-hook';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

function MyTimer({ expiryTimestamp, autoStart}) {
    const {
      seconds,
      minutes,
      start,
      pause,
      resume,
      restart,
    } = useTimer({ expiryTimestamp, autoStart });

    return (
        <div style={{textAlign: 'center'}}>
            <span>{minutes}</span>:<span>{seconds}</span>
            <div id="timerStart" onClick={start}></div>
        </div>
    );
}

function VideoRecorder() {

    const time = new Date();
    time.setSeconds(time.getSeconds() + 60);

    const [show, setShow] = useState(false);
    const [recording, setRecording] = useState(false);

    const handleClose = () => {
        setShow(false);
        window.location.reload();
    };
    const handleShow = () => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true})
        .then((stream) => {
            mediaRecorder = new MediaRecorder(stream);
            document.getElementById("video").srcObject = stream;
            document.getElementById("startbtn").onclick = function() {
                mediaRecorder.start(1000);
                mediaRecorder.ondataavailable = function(event) {
                    parts.push(event.data);
                }
                mediaRecorder.onerror = function(error) {
                    console.log(error);
                }
                setRecording(true);
                document.getElementById("timerStart").click();
                setTimeout(() => {
                    mediaRecorder.stop();
                    const blob = new Blob(parts, {
                        type: 'video/webm'
                    });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = url;
                    a.download = "test.webm";
                    a.click();
                    
                    mediaRecorder.stream.getTracks().forEach(function(track) {
                        track.stop();
                    });
    
                    setRecording(false);
    
                    mediaRecorder.onerror = function(error) {
                        console.log(error);
                    }                    
                }, 60000)
            }
            document.getElementById("stopbtn").onclick = function() {
                mediaRecorder.stop();
                const blob = new Blob(parts, {
                    type: 'video/webm'
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = "test.webm";
                a.click();
                
                mediaRecorder.stream.getTracks().forEach(function(track) {
                    track.stop();
                });

                setRecording(false);

                mediaRecorder.onerror = function(error) {
                    console.log(error);
                }
            }
        })
        setShow(true)
    };

    let parts = [];

    let mediaRecorder;

    window.onload = () => {
    };

    return (
        <div className={styles.VideoRecorder}>
            <Modal show={show} onHide={handleClose} className={styles.Modal}>
                <button variant="secondary" onClick={handleClose} className={styles.ExitButton} id="exit">
                    <img src="https://i3.lensdump.com/i/tEG85k.png"/>
                </button>
                <div className={styles.RecordingState} id="recording" style={{ display: recording? "block" : "none" }}>
                    <Row>
                        <Col xs={2}>
                            <div className={styles.RecordingIndicator}></div>
                        </Col>
                        <Col>
                            <span>Recording</span>
                        </Col>
                        <Col>
                            <MyTimer expiryTimestamp={time}/>
                        </Col>
                    </Row>
                </div>
                <video muted id="video" autoPlay playsInline />
                <button id="startbtn" style={{ backgroundColor: '#3B4DE4' }}><img src="https://i.imgur.com/PQVvHqR.png" width="24px" style={{ marginRight: "1rem" }}/>Start Recording</button>
                <button id="stopbtn" style={{ backgroundColor: '#0A0F34' }}><img src="https://i.imgur.com/Ste0fSd.png" width="24px" style={{ marginRight: "1rem" }}/>Stop Recording</button>
            </Modal>
            <button variant="primary" onClick={() => handleShow()} className={GlobalStyles.DarkButton} style={{ marginBottom: "2rem" }}>
                Get Started
            </button>
        </div>
    )
};

export default VideoRecorder;