import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './JobPosting.module.css';

import { useState, useEffect } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import {collection, getDocs, updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore/lite';
import {app, db} from '../../util/firebase';
import { getAuth, onAuthStateChanged, updateProfile } from "firebase/auth";

import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const auth = getAuth();

const parser = new DOMParser();
let activeUser;

function JobPosting(props) {

    const params = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const [job, setJob] = useState({});
    const [jobSaved, setJobSaved] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                activeUser = {...user};
            } else {
        
            }
        })
    }, [auth, db]);

    useEffect(() => {
        getJobs(db)
        .then((jobs) => {

            let timePosted = 0;
            if(((Date.now() - jobs[0]._document.data.value.mapValue.fields.timestamp.stringValue)/1000)/60 < 60) {
                timePosted = "Posted " + Math.round(((Date.now() - jobs[0]._document.data.value.mapValue.fields.timestamp.stringValue)/1000)/60) + " minutes ago"
            } else {
                timePosted = "Posted " + Math.round((((Date.now() - jobs[0]._document.data.value.mapValue.fields.timestamp.stringValue)/1000)/60)/60) + " hours ago"
            };
            let parsedStr = parser.parseFromString(jobs[0]._document.data.value.mapValue.fields.description.stringValue, 'text/html');
            if (document.getElementById("htmlDesc")) {
                document.getElementById("htmlDesc").innerHTML = `<p>${parsedStr.body.innerHTML}</p>`;
            }
            setJob({...jobs[0]._document.data.value.mapValue.fields, timePosted: timePosted, id: jobs[0].id})
            
        })
    },[setJob]);

    async function getJobs(db) {
        const jobsCol = collection(db, 'jobs');
        const jobsSnapshot = await getDocs(jobsCol);
        const jobsData = jobsSnapshot.docs.filter(doc => doc.id === searchParams.get('id'));
        return jobsData;
    };

    async function saveJob(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        await updateDoc(userRef, {
            savedJobs: arrayUnion(searchParams.get("id"))
        });
        window.location.reload();
    };

    async function deleteSavedJob(db) {
        const userRef = doc(db, 'users', activeUser.uid);
        await updateDoc(userRef, {
            savedJobs: arrayRemove(searchParams.get("id"))
        });
        window.location.reload();
    };

    return (
        <Container className={styles.JobPosting}>
                {job.position ? 
                    (                   
                        <>
                            <h3>{job.position.stringValue}</h3>
                            <h5>{job.employer.stringValue}</h5>
                            <span>{job.city.stringValue}</span>
                            <p>{job.jobType.stringValue}</p>
                            <p>{job.timePosted}</p>
                            <Link to={`/job/${searchParams.get('id')}/apply`}>
                                <button className={GlobalStyles.DarkButton}>Apply</button>
                            </Link>
                            {
                                props.savedJobs.length > 0 ?
                                    props.savedJobs.map((savedJob) => (
                                        savedJob.id === job.id ? <span className={styles.SaveButton} onClick={() => deleteSavedJob(db)}>Saved</span> : <span className={styles.SaveButton} onClick={() => saveJob(db)}>Save</span>
                                    ))
                                :
                                <span onClick={() => saveJob(db)} className={styles.SaveButton}>Save</span>
                            }
                            <hr />
                            {/* <div>
                                {job.description.stringValue}
                            </div> */}
                            <div id="htmlDesc"></div>
                            <hr />
                            <a>Report this job</a>
                        </>
                    ):
                    null
                }
        </Container>
    )
};

export default JobPosting;