import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './Home.module.css';

import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import _ from 'lodash';

import axios from 'axios';

import app from '../../util/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

let locationArray = ["Sydney", "Melbourne", "Brisbane", "Perth", "NSW", "Victoria", "New South Wales"];

const auth = getAuth();

let activeUser = {};

function Home() {

  let searchArray = ["software engineer", "technical recruiter", "IT support", "chef", "warehouse associate"];

//   useEffect(() => {
//     console.log("component rendered");
//   })

  const [hover, setHover] = useState({});

  function handleTabHover(event) {
    console.log(event);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            activeUser = {...user};          
        } else {
    
        }
    })
  }, [auth]);

  return (
    <div className={styles.Home}>
        <Container as={'section'} className={styles.SignIn} style={{ display: auth.currentUser ? "none" : "block" }}>
            <p >Sign in to see your saved jobs and applications</p>
            <Container style={{ display: "flex", justifyContent: "center"}}>
                <Row className={styles.SignInRow}>
                    <Col xs={3} s={1}>
                        <Link to="/login" 
                        style={{ color: "#3B4DE4", fontWeight: "700", textAlign: "center" }}                     
                        onMouseEnter={() => setHover({"SignIn" : true})}
                        onMouseLeave={() => setHover({})}>
                        Sign In <div className={GlobalStyles.Underline} style={{ width: hover.SignIn? "100%" : "0", backgroundColor: "#3B4DE4"}}></div>
                        </Link>
                    </Col>
                    <Col xs={1}><span>|</span></Col>
                    <Col xs={3} s={1}>
                        <Link to="/register"
                        style={{ color: "#0A0F34", fontWeight: "700", textAlign: "center" }}
                        onMouseEnter={() => setHover({"Register": true})}
                        onMouseLeave={() => setHover({})}>
                        Register <div className={GlobalStyles.Underline} style={{ width: hover.Register? "100%" : "0" }}></div>
                        </Link>
                    </Col>
                </Row>
                {/* <a style={{ color: "#3B4DE4", fontWeight: "700", textAlign: "center" }}>Sign In</a>
                <span>|</span>
                <a style={{ color: "#0A0F34", fontWeight: "700", textAlign: "center" }}>Register</a> */}
            </Container>
            {/* <img src="https://i3.lensdump.com/i/tEYPJT.png"/> */}
        </Container>
        <section style={{ backgroundColor: "#F6F7F8", padding: "1rem 0 2rem 0" }}>
            <Container>
                <Row className={styles.Section}>
                    <Col lg={5} style={{ height: "440px", textAlign: "center"}} className={styles.SectionOne}>
                        <h4>Want to get hired?</h4>
                        <p>It's as easy as 1-2-3!</p>
                        <Row className={styles.SectionRow}>
                            <Col className={styles.Step}>
                                <div>1</div>
                                <img src="https://i.imgur.com/WkKDDy5.png" />
                                <h6>Search</h6>
                            </Col>
                            <Col className={styles.Step}>
                                <div>2</div>
                                <img src="https://i.imgur.com/OHayOK9.png"/>
                                <h6>Interview</h6>
                            </Col>
                            <Col className={styles.Step}>
                                <div>3</div>
                                <img src="https://i.imgur.com/KlhhWkb.png"/>
                                <h6>Get Hired</h6>
                            </Col>
                        </Row>
                        <br/>
                        <button className={GlobalStyles.DarkButton}>Get Started</button>
                    </Col>
                    <Col lg={1} style={{ borderRight: "1px solid #cfcfcf" }}></Col>
                    <Col lg={6} style={{ height: "440px", textAlign: "center"  }}>
                        <h4>Want to hire the best?</h4>
                        <p>Post a job in less than five minutes!</p>
                        <Row className={styles.SectionRow}>
                            <Col>
                            <img src="https://i.imgur.com/BE5OKv7.png" width="40%"/>
                            </Col>
                        </Row>
                        <br/>
                        <button className={GlobalStyles.DarkButton}>Post a Job</button>
                    </Col>
                </Row>
            </Container>
        </section>
        <section>
            <Container className={styles.PopularSearches}>
                <h4>Trending Jobs</h4>
                <Row style={{ width: "78%", marginBottom: "1rem" }}>
                    <Col className={styles.Tab}>
                        <span>Job Titles</span>
                        <div className={`${styles.TabSelect} ${styles.TabSelectActive}`}></div>
                    </Col>
                    <Col className={styles.Tab}>
                        <span>Locations</span>
                        <div className={styles.TabSelect}></div>
                    </Col>
                    <Col className={styles.Tab}>
                        <span>Job Types</span>
                        <div className={styles.TabSelect}></div>
                    </Col>
                    <Col className={`${styles.Tab} ${styles.HideOnMobile}`}>
                        <span>Popular Companies</span>
                        <div className={styles.TabSelect}></div>
                    </Col>
                </Row>
                <Row className={styles.PopularSearchesRow}>
                    {searchArray.map((keyword, index) => (
                        <Col key={index} xs={6} md={3}>
                            <button className={GlobalStyles.DarkFilterButton}>{keyword}</button>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    </div>
  );
}

export default Home;