import styles from './Footer.module.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer() {
    return (
        <div className={styles.Footer}>
            <Container>
                <Row>
                    <Col md={3} className={styles.FooterCol}>
                        <h6>Tools</h6>
                        <a>Job search</a>
                        <a>Profile</a>
                        <a>Saved Searches</a>
                        <a>Career Advice</a>
                        <a>Resume Review</a>
                        <a>Company Reviews</a>
                    </Col>
                    <Col md={3} className={styles.FooterCol}>
                        <h6>Company</h6>
                        <a>About 60 Seconds Resume</a>
                        <a>Newsroom</a>
                        <a>Investors</a>
                    </Col>
                    <Col md={3} className={styles.FooterCol}>
                        <h6>Connect</h6>
                        <a>Contact us</a>
                        <a>Careers</a>
                        <a>Blog</a>
                        <a>Facebook</a>
                        <a>Instagram</a>
                    </Col>
                    <Col md={3} className={styles.FooterCol}>
                        <h6>Employers</h6>
                        <a>Register</a>
                        <a>Post a job</a>
                        <a>Customer Service</a>
                        <a>Market Insights</a>
                    </Col>
                </Row>
                <Row className={styles.FooterRowTwo}>
                    <a>Privacy</a>
                    <a>Terms & Conditions</a>
                    <span>© 60 Seconds Resume. All rights reserved.</span>
                </Row>
            </Container>
        </div>
    )
};

export default Footer;