import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './Header.module.css';

import { useState, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import _ from 'lodash';

import {collection, getDocs } from 'firebase/firestore/lite';
import {app, db} from '../../util/firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

let searchArray = ["software engineer", "technical recruiter", "IT support", "chef", "warehouse associate"];
let locationArray = ["Sydney", "Melbourne", "Brisbane", "Perth", "NSW", "Victoria", "New South Wales"];

// Filter Options Array
const jobType = ["Full-Time", "Contract", "Part-Time", "Internship"];
const remote = ["Remote"];
const salary = ["$25,000+", "$45,000+", "$55,000+", "$65,000+"];
const datePosted = ["Any time", "Today", "Last 3 days", "Last 7 days", "Last 14 days", "Last 30 days"]

function Header() {

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        getJobs(db)
        .then((data) => {
            data.forEach(function(childSnapshot) {
                setJobs((prevValues) => {
                    return [
                        ...prevValues.filter(value => value !== childSnapshot.position),
                        childSnapshot.position
                    ]
                })
            })
        })
      }, [])

    const [searchParams, setSearchParams] = useSearchParams();

    const [hover, setHover] = useState({});

    const [formValues, setFormValues] = useState({
        keywords: "",
        location: ""
    });
  
    const [showList, setShowList] = useState({});

    async function getJobs(db) {
        const jobsCol = collection(db, 'jobs');
        const jobsSnapshot = await getDocs(jobsCol);
        const jobsList = jobsSnapshot.docs.map(doc => doc.data());
        return jobsList;
    }
  
    const handleInputChange = (event) => {
      const {name, value} = event.target;
          setTimeout(setShowList({[name] : true}), 200)
          setTimeout(setFormValues((prevValues) => {
              return {
                  ...prevValues,
                  [name] : value
              }
          }),200)
      }
    
  
    function handleResultClick(event, name, value) {
      event.cancelBubble = true;
      setTimeout(setFormValues((prevValues) => {
          return {
              ...prevValues,
              [name]: value
          }
      }),1000)
      setShowList({});
    };

    function handelFilterClick(event, name) {
        event.cancelBubble = true;
        if (event.target.childNodes[1].className === 'Header_UpsideDownImg__nwx3c') {
            event.target.childNodes[1].className = "";
            setShowList({});
        } else {
            event.target.childNodes[1].className = styles.UpsideDownImg;
            setShowList({[name]: true});
        }
    };

    function handleFilterOptionClick(event, name, value) {
        event.cancelBubble = true;
        let filteredSearchParams;
        filteredSearchParams = [...searchParams.entries()].filter((param) => param[0] !== name);
        setSearchParams([...filteredSearchParams, [[name], value]]);
        window.location.reload();
    };

    function handleRemoveFilter(event, name, value) {
        event.cancelBubble = true;
        let filteredSearchParams;
        filteredSearchParams = [...searchParams.entries()].filter((param) => param[0] !== name);
        setSearchParams([...filteredSearchParams])
        window.location.reload();
    };

    function handleSearch() {
        window.location.replace(`/jobs?title=${formValues.keywords}&location=${formValues.location}`)
    }

    function handleBackdropClick() {
        setShowList({});
        document.getElementsByClassName('Header_UpsideDownImg__nwx3c')[0].className = "";
    }

    return (
        <div className={styles.Header} style={{display: window.location.pathname.includes("/activity") || window.location.pathname.includes("/apply") || window.location.pathname.includes("/settings") ? 'none' : 'block'}}>
            <div className={styles.Backdrop} onClick={handleBackdropClick} style={{ display: _.isEmpty(showList) ? "none" : "block" }}></div>
            <section className={styles.SearchContainer} id="SearchJobs" style={{ display: window.location.pathname.includes(`/login`) || window.location.pathname.includes("/register") || window.location.pathname.includes("/dashboard") ? "none" : "block" }}>
                <Container className={styles.Search}>
                    <Row className={styles.SearchRow}>
                        <Col lg={5} style={{ position: "relative"}}>
                            <input 
                            type='text' 
                            placeholder='Job title, keywords' 
                            name="keywords"
                            value={formValues.keywords}
                            autoComplete="off"
                            onChange={(event) => handleInputChange(event)}/>
                            <aside className={styles.DropdownList} style={{ borderTop: showList.keywords && formValues.keywords.length !== 0 ? "1px solid" : "none" }}>
                                {jobs.map((term, index) => (
                                    showList.keywords && formValues.keywords.length !== 0 && term.toLowerCase().includes(formValues.keywords.toLowerCase()) ? <div className={styles.DropListItem} key={index} onClick={(event) => handleResultClick(event,'keywords', term)}>{term}</div> : null
                                ))}
                            </aside>
                        </Col>
                        <Col lg={5} style={{ position: "relative" }}>
                            <input 
                            type='text' 
                            placeholder='city, region' 
                            name="location"
                            value={formValues.location}
                            autoComplete="off"
                            onChange={(event) => handleInputChange(event)}/>
                            <aside className={styles.DropdownList} style={{ borderTop: showList.location && formValues.location.length !== 0 ? "1px solid" : "none" }}>
                                {locationArray.map((term, index) => (
                                    showList.location && formValues.location.length !== 0 && term.toLowerCase().includes(formValues.location.toLowerCase()) ? <div className={styles.DropListItem} key={index} onClick={(event) => handleResultClick(event,'location', term)}>{term}</div> : null
                                ))}
                            </aside>
                        </Col>
                        <Col lg={2} style={{ position: "relative"}}>
                            <button className={GlobalStyles.DarkButton} onClick={handleSearch}>Search</button>
                        </Col>
                        <p>
                        For Employers: 
                        <span>
                            <strong>
                                <a onMouseEnter={() => setHover({"PostJob" : true})} onMouseLeave={() => setHover({})}>
                                    Post a Job 
                                    <div className={GlobalStyles.Underline} style={{ width: hover.PostJob? "100%" : "0", backgroundColor: "#fff"}}></div>
                                </a>
                            </strong>
                        </span>
                        </p>
                        <Row className={styles.FiltersRow} style={{ display: window.location.pathname.includes("/jobs") ? "flex" : "none"}}>
                            <Col xs={6} md={3} className={styles.Filter} style={{ display: searchParams.get('type') ? "none" : "block" }}>
                                <button className={GlobalStyles.FilterButton} onClick={(event) => handelFilterClick(event, 'jobType')}>Job Type <img src="https://i.lensdump.com/i/tEwYIM.png" width="10px"/></button>
                                <aside className={styles.DropdownList}>
                                    {jobType.map((type, index) => (
                                        showList.jobType ? <div className={styles.DropListItem} key={index} onClick={(event) => handleFilterOptionClick(event, 'type', type )}>{type}</div> : null
                                    ))}
                                </aside>
                            </Col>
                            <Col className={styles.FilterApplied} xs={6} md={3} style={{ display: searchParams.get('type') ? "block" : "none" }}>
                                <button className={GlobalStyles.FilterAppliedButton} onClick={(event) => handleRemoveFilter(event, 'type', searchParams.get('type'))}>{searchParams.get('type')}  <img src="https://i3.lensdump.com/i/tEG85k.png" width="10px"/></button>
                            </Col>
                            <Col xs={6} md={3} className={styles.Filter} style={{ display: searchParams.get('remote') ? "none" : "block" }}>
                                <button className={GlobalStyles.FilterButton} onClick={(event) => handelFilterClick(event, 'remote')}>Remote <img src="https://i.lensdump.com/i/tEwYIM.png" width="10px"/></button>
                                <aside className={styles.DropdownList}>
                                    {remote.map((type, index) => (
                                        showList.remote ? <div className={styles.DropListItem} key={index} onClick={(event) => handleFilterOptionClick(event, 'remote', type )}>{type}</div> : null
                                    ))}
                                </aside>
                            </Col>
                            <Col className={styles.FilterApplied} xs={6} md={3} style={{ display: searchParams.get('remote') ? "block" : "none" }}>
                                <button className={GlobalStyles.FilterAppliedButton} onClick={(event) => handleRemoveFilter(event, 'remote', searchParams.get('remote'))}>{searchParams.get('remote')}  <img src="https://i3.lensdump.com/i/tEG85k.png" width="10px"/></button>
                            </Col>
                            <Col xs={6} md={3} className={styles.Filter} style={{ display: searchParams.get('salary') ? "none" : "block" }}>
                                <button className={GlobalStyles.FilterButton} onClick={(event) => handelFilterClick(event, 'salary')}>Salary <img src="https://i.lensdump.com/i/tEwYIM.png" width="10px"/></button>
                                <aside className={styles.DropdownList}>
                                    {salary.map((type, index) => (
                                        showList.salary ? <div className={styles.DropListItem} key={index} onClick={(event) => handleFilterOptionClick(event, 'salary', type )}>{type}</div> : null
                                    ))}
                                </aside>                            
                            </Col>
                            <Col className={styles.FilterApplied} xs={6} md={3} style={{ display: searchParams.get('salary') ? "block" : "none" }}>
                                <button className={GlobalStyles.FilterAppliedButton} onClick={(event) => handleRemoveFilter(event, 'salary', searchParams.get('salary'))}>{searchParams.get('salary')}  <img src="https://i3.lensdump.com/i/tEG85k.png" width="10px"/></button>
                            </Col>
                            <Col xs={6} md={3} className={styles.Filter} style={{ display: searchParams.get('datePosted') ? "none" : "block" }}>
                                <button className={GlobalStyles.FilterButton} onClick={(event) => handelFilterClick(event, 'datePosted')}>Date Posted <img src="https://i.lensdump.com/i/tEwYIM.png" width="10px"/></button>
                                <aside className={styles.DropdownList}>
                                    {datePosted.map((type, index) => (
                                        showList.datePosted ? <div className={styles.DropListItem} key={index} onClick={(event) => handleFilterOptionClick(event, 'datePosted', type )}>{type}</div> : null
                                    ))}
                                </aside>   
                            </Col>
                            <Col className={styles.FilterApplied} xs={6} md={3} style={{ display: searchParams.get('datePosted') ? "block" : "none" }}>
                                <button className={GlobalStyles.FilterAppliedButton} onClick={(event) => handleRemoveFilter(event, 'datePosted', searchParams.get('datePosted'))}>{searchParams.get('datePosted')}  <img src="https://i3.lensdump.com/i/tEG85k.png" width="10px"/></button>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </section>            
        </div>
    )
};

export default Header;