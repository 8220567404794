import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './SavedJobs.module.css';

import { useEffect, useState } from 'react';

import {collection, getDocs, updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore/lite';
import { app, db } from "../../util/firebase";
import { getAuth, onAuthStateChanged, updateProfile } from "firebase/auth";

import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const auth = getAuth();
let activeUser;

function SavedJobs(props) {

    const [tab, setTab] = useState('savedJobs');

    const [hover, setHover] = useState({});

    function handleRemoveSavedJob(jobId) {
        props.setSavedJobs((prevValues) => {
            return [
                ...prevValues.filter(job => job.id !== jobId),
            ]
            window.location.reload();
        })
    };

    return (
        <div className={styles.SavedJobs} style={{backgroundColor: "#F6F7F8"}}>
            <Container>
                <h3>My Activity</h3>
                <Row style={{width: "40%"}}>
                    <Col>
                        <a onMouseEnter={() => setHover({"savedJobs" : true})} onMouseLeave={() => setHover({})} className={styles.Tab} onClick={() => setTab('savedJobs')}>
                            Saved Jobs
                            <div className={GlobalStyles.Underline} style={{ width: hover.savedJobs || tab === 'savedJobs' ? "100%" : "0" }}></div>
                        </a>
                    </Col>
                    <Col>
                    <a onMouseEnter={() => setHover({"applied" : true})} onMouseLeave={() => setHover({})} className={styles.Tab} onClick={() => setTab('applied')}>
                            Applied
                            <div className={GlobalStyles.Underline} style={{ width: hover.applied || tab === 'applied' ? "100%" : "0" }}></div>
                        </a>
                    </Col>
                </Row>
                    <div style={{ display: tab === "savedJobs" ? "block" : "none" }}>
                        {
                            props.savedJobs.length > 0 ?
                                props.savedJobs.map((savedJob) => (
                                    <div className={styles.Card} key={savedJob.id}>
                                        <h6>{savedJob.position.stringValue}</h6>
                                        <span>{savedJob.employer.stringValue}</span>
                                        <p>{
                                            Math.round((((Date.now() - savedJob.timestamp.stringValue)/1000)/60)/60) < 24 ?
                                            Math.round(((Date.now() - savedJob.timestamp.stringValue)/1000)/60) < 60 ?
                                            `Job posted ${Math.round(((Date.now() - savedJob.timestamp.stringValue)/1000)/60)} minutes ago`:
                                            `Job posted ${Math.round((((Date.now() - savedJob.timestamp.stringValue)/1000)/60)/60)} hours ago`:
                                            `Job posted ${Math.round(((((Date.now() - savedJob.timestamp.stringValue)/1000)/60)/60)/24)} days ago`
                                        }</p>
                                        <span>{savedJob.city.stringValue}</span>
                                        <br/>
                                        <Link to={`/job/${savedJob.id}/apply`}>
                                            <button className={GlobalStyles.DarkButton} style={{marginTop: "1rem", marginRight: "1rem"}}>Start Application</button>
                                        </Link>
                                        <span onClick={() => handleRemoveSavedJob(savedJob.id)}>Remove</span>
                                    </div>
                                ))
                            :
                            <div className={styles.Card}>
                            <p>You have no saved jobs</p>
                            </div>
                        }
                    </div>
                    <div style={{ display: tab === "applied" ? "block" : "none" }}>
                        <div className={styles.Card}>
                            <p>You have not applied to any jobs yet</p>
                        </div>
                    </div>
            </Container>
        </div>
    )
};

export default SavedJobs;