import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './Register.module.css';

import { useState, useEffect } from 'react';

import { Navigate } from 'react-router-dom';

import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore/lite";
import { db } from '../../util/firebase'; 

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const auth = getAuth();

function UnauthorizedContent() {

    const [hover, setHover] = useState({});

    const [formErrors, setFormErrors] = useState({
        email: '',
        password: '',
        formError: ''
    })

    const [formValues, setFormValues] = useState({
        email: '',
        password: ''
    })

    function handleRegister() {
        createUserWithEmailAndPassword(auth, formValues.email, formValues.password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          // ...
          registerNewUserProfile(db, user)
          .then((data) => {
              window.location.replace("/dashboard")
          })
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorMessage.includes("auth/email-already-in-use")) {
            setFormErrors((prevValues) => {
                return {
                    ...prevValues,
                    formError: "This email is already in use"
                }
            })
          }
          // ..
        });
    };

    function handleInputChange(event) {
        const {name, value} = event.target;
        if (value.length === 0) {
            setFormErrors((prevValues) => {
                return {
                    ...prevValues,
                    [name]: 'This field is required'
                }
            })
        } else {
            setFormErrors((prevValues) => {
                return {
                    ...prevValues,
                    [name]: ''
                }
            })
        }
        if (name === 'email') {
            if (!value.match(regexEmail)) {
                setFormErrors((prevValues) => {
                    return {
                        ...prevValues,
                        email: 'Please enter a valid email'
                    }
                })
            } else {
                setFormErrors((prevValues) => {
                    return {
                        ...prevValues,
                        email: ''
                    }
                })                
            }
        }
        setFormValues((prevValues) => {
            return {
                ...prevValues,
                [name]: value
            }
        })
    };

    async function registerNewUserProfile(db, user) {
        await setDoc(doc(db, "users", user.uid), {
            firstName: '',
            lastName: '',
            location: '',
            phoneNumber: '',
            email: formValues.email,
            careerHistory: [],
            education: [],
            licenses: [],
            skills: [],
            languages: [],
            personalSummary: ''
        });
    };

    return (
            <Container fluid className={styles.Card}>
                <img src="https://i.imgur.com/kw0hMNk.png" className={styles.DesktopImage}/>
                <img src="https://i.lensdump.com/i/tk2N40.png" className={styles.MobileImage}/>
                <h2>Register</h2>
                <input type="email" placeholder='Email Address' value={formValues.email} name="email" onChange={(event) => handleInputChange(event)}/>
                <p style={{color: "#b71e1e", display: formErrors.email ? 'inline' : 'none'}}><img src="https://i.imgur.com/9XWhhz2.png" width="20px"/> {formErrors.email}</p>
                <br style={{display: formErrors.email ? 'inline' : 'none'}}/>
                <br/>
                <input type="password" placeholder='Password' value={formValues.password} name="password" onChange={(event) => handleInputChange(event)}/>
                <p style={{color: "#b71e1e", display: formErrors.password ? 'inline' : 'none'}}><img src="https://i.imgur.com/9XWhhz2.png" width="20px"/> {formErrors.password}</p>
                <br style={{display: formErrors.password ? 'inline' : 'none'}}/>
                <br/>
                <button className={GlobalStyles.DarkButton} style={{ marginBottom: "2rem" }} onClick={handleRegister}>Register</button>
                <div className={styles.MainFormError} style={{ display: formErrors.formError ? "block" : "none" }}><img src="https://i.imgur.com/9XWhhz2.png" width="20px"/>{formErrors.formError}</div>
                <p>Already have an account? <span><a href="/login" onMouseEnter={() => setHover({"SignIn": true})}onMouseLeave={() => setHover({})}>Sign In <div className={GlobalStyles.Underline} style={{ width: hover.SignIn? "100%" : "0", backgroundColor: "#3B4DE4"}}></div></a></span></p>
                <p>Employer? <span><a onMouseEnter={() => setHover({"EmployerSignIn": true})}onMouseLeave={() => setHover({})}>Sign in here <div className={GlobalStyles.Underline} style={{ width: hover.EmployerSignIn? "100%" : "0", backgroundColor: "#3B4DE4"}}></div></a></span></p>
            </Container>

    )
};

function Register() {

    const [loggedIn, setLogInState] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setLogInState(true);
            } else {
        
            }
        })
    }, [auth, setLogInState])


    return (
        <div className={styles.Register}>
            {auth.currentUser || loggedIn ? <Navigate to="/dashboard"/> : <UnauthorizedContent />}
        </div>
    )
};

export default Register