
import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './Dashboard.module.css';

import React, { useState, useEffect, Suspense } from 'react';

import { getAuth, onAuthStateChanged, updateProfile } from "firebase/auth";

import {collection, getDocs } from 'firebase/firestore/lite';
import {app, db} from '../../util/firebase';

import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const auth = getAuth();

const EditPersonalDetailsFormSuspense = React.lazy(() => import('../../components/EditPersonalDetailsForm/EditPersonalDetailsForm'));
const DashboardHeader = React.lazy(() => import('../../components/DashboardHeader/DashboardHeader'));

let activeUser;

function Dashboard() {

    const [profile, setProfileDetails] = useState({});

    const [modalShow, setModalShow] = useState(false);

    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        location: '',
        phoneNumber: '',
        email: ''
    });

    const [resumeData, setResumeData] = useState({
        firstName: '',
        lastName: '',
        location: '',
        phoneNumber: '',
        email: '',
        careerHistory: [],
        education: [],
        licenses: [],
        skills: [],
        languages: [],
        personalSummary: ''
    })

    const [resumeComplete, setResumeComplete] = useState(false);


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                activeUser = {...user};
                setProfileDetails({...user})
            } else {
        
            }
        })
    }, [auth, setProfileDetails, setFormValues, db]);

    useEffect(() => {
        getUsers(db)
        .then((data) => {
            const {firstName, lastName, location, phoneNumber, email, careerHistory, education, licenses, skills, languages, personalSummary} = data[0]._document.data.value.mapValue.fields;
            setResumeData((prevValues) => {
                return {
                    ...prevValues,
                    firstName: firstName.stringValue,
                    lastName: lastName.stringValue,
                    location: location.stringValue,
                    phoneNumber: phoneNumber.stringValue,
                    email: email.stringValue,
                    careerHistory: careerHistory.arrayValue.values && careerHistory.arrayValue.values.length > 0 ? careerHistory.arrayValue.values : [] ,
                    education: education.arrayValue.values && education.arrayValue.values.length > 0 ? education.arrayValue.values : [],
                    licenses: licenses.arrayValue.values && licenses.arrayValue.values.length > 0 ? licenses.arrayValue.values : [],
                    skills: skills.arrayValue.values && skills.arrayValue.values.length > 0 ? skills.arrayValue.values : [],
                    languages: languages.arrayValue.values && languages.arrayValue.values.length > 0 ? languages.arrayValue.values : [],
                    personalSummary: personalSummary.stringValue
                }
            })
            setResumeComplete([firstName, lastName, location, phoneNumber, email, personalSummary, careerHistory, education, licenses, skills, languages].every((item) => {
                if (item.stringValue) {
                    return item.stringValue.length > 0
                } else if (item.arrayValue){
                    return item.arrayValue.values.length > 0
                }
            }));
        })
    }, [setResumeData, setResumeComplete]);

    async function getUsers(db) {
        const usersCol = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCol);
        const userData = usersSnapshot.docs.filter(doc => doc.id === activeUser.uid);
        // const userData = usersList[0]._document.data.value.mapValue.fields;
        return userData;
    };


    function handleSave() {
        updateProfile(auth.currentUser, {
            displayName: formValues.firstName + " " + formValues.lastName,
            location: formValues.location,
            email: formValues.email
        }).then((result) => {
            console.log(result);
        }).catch((error) => {
            console.log(error);
        })

    };

    return (
        <div className={styles.Dashboard} style={{backgroundColor: "#F6F7F8"}}>
            <div style={{ backgroundColor: '#3B4DE4', color: '#fff' ,padding: '2rem 0' ,textAlign: 'center' }}>
                <div className={styles.Modal} style={{ display: modalShow? 'block' : 'none' }}>
                    <h4>Edit personal details</h4>
                    <div>
                        <Suspense fallback={<div>Loading...</div>}>
                            <EditPersonalDetailsFormSuspense user={profile} setModalShow={setModalShow}/>
                        </Suspense>
                    </div>
                </div>
                <Suspense fallback={<div>Loading...</div>}>
                    <DashboardHeader activeUser={activeUser} setModalShow={setModalShow}/>
                </Suspense>
                {/* <p>Add Location <img src='https://i.imgur.com/VTEjfol.png' width="16px"/></p> */}
                <h6 className={styles.email}>{profile.email}</h6>
                <h6 className={styles.email}>{resumeData.phoneNumber}</h6>
                <br/>
                <button className={GlobalStyles.FilterButton} style={{ width: "auto", padding: "0.5rem 1rem", fontSize: "1rem" }} onClick={() => setModalShow(true)}>Edit Personal Information <img src='https://i.imgur.com/VTEjfol.png' width="16px"/></button>
            </div>
            {resumeData? 
                (
                    <>

                        <Container className={styles.Card}>
                            <Row>
                                <Col>
                                    <h5>Personal Summary</h5>
                                </Col>
                                <Col>
                                    <a href={`/dashboard/create-resume?edit=personal-summary&item=personal-summary`}>Edit</a>                                
                                </Col>
                            </Row>
                            {resumeData.personalSummary.length > 0? <p>{resumeData.personalSummary}</p> : <p>Add a personal summary to your profile as a way to introduce who you are</p>}
                        </Container>

                        <Container className={styles.Card}>
                            <h5>Career History</h5>
                            {
                                resumeData.careerHistory.length > 0? 
                                    resumeData.careerHistory.map((item, index) => (
                                        <Row key={index}>
                                            <Col>
                                                <h6>{item.mapValue.fields.jobTitle.stringValue}</h6>
                                                <p>{item.mapValue.fields.companyName.stringValue}</p>
                                                <span>
                                                    {`${item.mapValue.fields.startedMonth.stringValue} ${item.mapValue.fields.startedYear.stringValue} - ${item.mapValue.fields.endedMonth.stringValue} ${item.mapValue.fields.endedYear.stringValue}`}
                                                </span>
                                                <br />
                                                <p>{item.mapValue.fields.description.stringValue}</p>
                                                
                                            </Col>
                                            <Col>
                                                <a href={`/dashboard/create-resume?edit=career-history&item=${item.mapValue.fields.id.stringValue}`}>Edit</a>
                                            </Col>
                                            <hr />
                                        </Row>
                                    )):
                                <>
                                    <p>Let employers know about your previous roles and your experience.</p>
                                </>
                            }
                            <a href={`/dashboard/create-resume?add=career-history`}>
                                <button className={GlobalStyles.DarkButton}>Add Role</button>
                            </a>
                        </Container>

                        <Container className={styles.Card}>
                            <h5>Education</h5>
                            {
                                resumeData.education.length > 0 ?
                                    resumeData.education.map((item) => (
                                        <Row>
                                            <Col>
                                                <h6>{item.mapValue.fields.institution.stringValue}</h6>
                                                <p>{item.mapValue.fields.course.stringValue}</p>
                                                <p>{item.mapValue.fields.finishedYear.stringValue}</p>                            
                                            </Col>
                                            <Col>
                                                <a href={`/dashboard/create-resume?edit=education&item=${item.mapValue.fields.id.stringValue}`}>Edit</a>
                                            </Col>
                                            <hr />
                                        </Row>
                                    )):
                                <p>Add certificates, degrees and more.</p>
                            }

                            <a href={`/dashboard/create-resume?add=education`}>
                                <button className={GlobalStyles.DarkButton}>Add Education</button>
                            </a>
                        </Container>   

                        <Container className={styles.Card}>
                            <h5>Licenses & Certifications</h5>
                            {
                                resumeData.licenses.length > 0? 
                                    resumeData.licenses.map((item) => (
                                        <Row>
                                            <Col>
                                                <h6>{item.mapValue.fields.licenseName.stringValue}</h6>
                                                <p>{item.mapValue.fields.issuingOrganisation.stringValue}</p>
                                                <p>{`${item.mapValue.fields.issuedMonth.stringValue} ${item.mapValue.fields.issuedYear.stringValue} - ${item.mapValue.fields.expiryMonth.stringValue} ${item.mapValue.fields.expiryYear.stringValue}`}</p>
                                                <p>{item.mapValue.fields.description.stringValue}</p>                            
                                            </Col>
                                            <Col>
                                                <a href={`/dashboard/create-resume?edit=licenses&item=${item.mapValue.fields.id.stringValue}`}>Edit</a>
                                            </Col>
                                            <hr />
                                        </Row>
                                    )):
                                <p>Showcase your professional credentials. Add your relevant licences, certificates, memberships and accreditations here.</p>
                            }
                            <a href={`/dashboard/create-resume?add=licenses`}>
                                <button className={GlobalStyles.DarkButton}>Add License / Certification</button>
                            </a>
                        </Container> 

                        <Container className={styles.Card}>
                            <h5>Skills</h5>
                            {
                                resumeData.skills.length > 0 ?
                                    resumeData.skills.map((item) => (
                                        <Row>
                                            <Col sm={6}>
                                                <p className={styles.Skill}>{item.stringValue} <img src="https://i3.lensdump.com/i/tEG85k.png" width="10px" /></p>
                                            </Col>
                                            <Col>
                                                <a href={`/dashboard/create-resume?edit=skills&item=skills`}>Edit</a>
                                            </Col>
                                        </Row>
                                    )):
                                <p>Let employers know how valuable you can be to them.</p>
                            }
                            <a href={`/dashboard/create-resume?add=skills`}>
                                <button className={GlobalStyles.DarkButton}>Add Skill</button>
                            </a>
                        </Container>

                        <Container className={styles.Card}>
                            <h5>Languages</h5>
                            {
                                resumeData.languages.length > 0?
                                    resumeData.languages.map((item) => (
                                        <Row>
                                            <Col sm={6}>
                                                <p className={styles.Skill}>{item.stringValue} <img src="https://i3.lensdump.com/i/tEG85k.png" width="10px" /></p>
                                            </Col>
                                            <Col>
                                                <a href={`/dashboard/create-resume?edit=languages&item=languages`}>Edit</a>
                                            </Col> 
                                        </Row>
                                    )):
                                    <p>Add languages to appeal to more companies and employers.</p>
                            }
                            <a href={`/dashboard/create-resume?add=languages`}>
                                <button className={GlobalStyles.DarkButton}>Add Language</button>
                            </a>
                        </Container>          

                        <Container className={styles.Card} style={{marginBottom: "0"}}>
                            <h5>Resumés</h5>
                            {resumeComplete ?
                                <>
                                    <div style={{display: "flex"}}>
                                        <div style={{marginRight: "1rem", display: "flex", alignItems: "center"}}>
                                            <img src="https://i2.lensdump.com/i/tBKcte.png" width="32px"/>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div>
                                                <span className={styles.Tag} style={{backgroundColor: "#cccdce"}}>Default</span>
                                                <span className={styles.Tag} style={{backgroundColor: "#8387AC", color: "#fff"}}>Generated from profile</span>
                                                <p style={{marginTop: "0.8rem", color: "#3B4DE4", marginBottom: "0"}}>Profile Resume - {`${resumeData.firstName} ${resumeData.lastName}`}</p> 
                                                <span>Added four days ago</span>
                                            </div> 
                                        </div>
                                    </div>
                                    <hr />
                                </>
                            : null}
                            <a>
                                <button className={GlobalStyles.DarkButton}>Add New Resume</button>
                            </a>
                        </Container>              
                    </>
                ):
                <Container className={styles.GetStarted}>
                    <h5>Create a new resume on 60 Seconds Resume</h5>
                    <Row as={'div'}>
                        <Col md={6}>
                            <Link to="create-resume">
                                <button className={GlobalStyles.DarkButton}>Create Resume</button>
                            </Link>
                        </Col>
                        <Col md={6}>
                            <a>Or upload your resume</a>
                        </Col>
                    </Row>
                </Container>
            }
        </div>
    )
};

export default Dashboard;