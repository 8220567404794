import GlobalStyles from '../../GlobalStyles.module.css';
import styles from './Settings.module.css';

import { useState } from 'react';

import _ from 'lodash';

import {reauthenticateWithCredential, EmailAuthProvider, updatePassword, signInWithCredential } from "firebase/auth";

import { Navigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Settings(props) {

    const [dropdown, setDropdown] = useState({
        changePassword: false,
        changeEmail: false,
        deleteAccount: false
    });
    
    const [formValues, setFormValues] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    })

    const [formErrors, setFormErrors] = useState({
        formError: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [passwordUpdated, setPasswordUpdated] = useState(false);

    function handleDropdownClick(dropdownTab) {
        setDropdown((prevValues) => {
            return {
                ...prevValues,
                [dropdownTab]: prevValues[dropdownTab] ? false : true
            }
        })
    };

    function handleInputChange(event) {
        const {name, value} = event.target;
        if (name === "currentPassword") {
            if (value.length === 0) {
                setFormErrors((prevValues) => {
                    return {
                        ...prevValues,
                        currentPassword: "This field is required"
                    }
                })
            } else {
                setFormErrors((prevValues) => {
                    return {
                        ...prevValues,
                        currentPassword: ""
                    }
                })
            }
        }
        if (name === "newPassword") {
            if (value.length < 8) {
                setFormErrors((prevValues) => {
                    return {
                        ...prevValues,
                        newPassword: "Password needs to be at least 8 characters"
                    }
                })
            } else {
                setFormErrors((prevValues) => {
                    return {
                        ...prevValues,
                        newPassword: ""
                    }
                })
            }
        }
        if (name === "confirmPassword") {
            if (value !== formValues.newPassword) {
                setFormErrors((prevValues) => {
                    return {
                        ...prevValues,
                        confirmPassword: "Password does not match"
                    }
                })
            } else {
                setFormErrors((prevValues) => {
                    return {
                        ...prevValues,
                        confirmPassword: ""
                    }
                })
            }
        }
        setFormValues((prevValues) => {
            return {
                ...prevValues,
                [name]: value
            }
        })
    };

    function validatePasswordInput() {

        for (const [key, value] of Object.entries(formValues)) {
            if (value.length === 0) {
                setFormErrors((prevValues) => {
                    return {
                        ...prevValues,
                        [key]: "This field is required"
                    }
                })
            };
        }

        reauthenticateUser();
    } 

    async function reauthenticateUser() {
        const {currentPassword, newPassword} = formValues;
        const credential =  EmailAuthProvider.credential(props.user.email, currentPassword);
        if (formErrors.currentPassword.length > 0 || formErrors.newPassword.length > 0 || formErrors.confirmPassword.length > 0) {
            setFormErrors((prevValues) => {
                return {
                    ...prevValues,
                    formError: "Please fix the issues on the form"
                }
            })
        } else {
            setFormErrors((prevValues) => {
                return {
                    ...prevValues,
                    formError: ""
                }
            })
            if (credential) {
                signInWithCredential(props.auth, credential)
                .then((response) => {
                    if(response._tokenResponse) {
                        updatePassword(props.auth.currentUser, newPassword).then(() => {
                            // Update successful.
                            setPasswordUpdated(true);
                            setDropdown((prevValues) => {
                                return {
                                    ...prevValues,
                                    changePassword: false
                                }
                            })
                          }).catch((error) => {
                            // An error ocurred
                            // ...
                          });
                    }
                }).catch((error) => {
                    setFormErrors((prevValues) => {
                        return {
                            ...prevValues,
                            formError: "Current password is incorrect"
                        }
                    })
                })             
            }
        }
    };


    return (
        <div className={styles.Settings} style={{backgroundColor: "#F6F7F8"}}>
            {props.user ? 
                <Container>
                    <h3>Settings</h3>
                    <div className={styles.Card}>
                        <div style={{paddingLeft: "1rem"}}>
                            {props.profile.firstName && <h5>{`${props.profile.firstName.stringValue} ${props.profile.lastName.stringValue}`}</h5>}
                            {props.profile.email && <span>{`${props.profile.email.stringValue}`}</span>}
                        </div>
                        <div className={styles.CardSection} style={{marginTop: "1rem"}}>
                            <Row>
                                <Col>
                                    <h6 onClick={() => {handleDropdownClick('changePassword'); setPasswordUpdated(false)}}>Change Password</h6>
                                    <div className={styles.CardForm} style={{display: dropdown.changePassword ? "block" : "none"}}>
                                        <p>{formErrors.formError}</p>
                                        <label>Current password</label>
                                        <br />
                                        <input type="password" name="currentPassword" value={formValues.currentPassword} onChange={(event) => handleInputChange(event)}/>
                                        <p>{formErrors.currentPassword}</p>
                                        <label>New password</label>
                                        <br />
                                        <input type="password" name="newPassword" value={formValues.newPassword} onChange={(event) => handleInputChange(event)}/>
                                        <p>{formErrors.newPassword}</p>
                                        <label>Confirm password</label>
                                        <br />
                                        <input type="password" name="confirmPassword" value={formValues.confirmPassword} onChange={(event) => handleInputChange(event)}/>
                                        <p>{formErrors.confirmPassword}</p>
                                        <button onClick={() => validatePasswordInput() } className={GlobalStyles.DarkButton}>Reset</button>
                                    </div>
                                    <div style={{display: passwordUpdated ? "block" : "none"}} className={styles.PasswordUpdatedConfirmation}>
                                        <span>Your password has been updated!</span>
                                    </div>
                                </Col>
                                <Col xs={2}>
                                    <img src="https://i3.lensdump.com/i/tBIjNC.png" width="24px" 
                                    style={{ transform: dropdown.changePassword ? "rotate(180deg)" : "none"}}
                                    onClick={() => {handleDropdownClick('changePassword'); setPasswordUpdated(false)}}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.CardSection}>
                            <Row>
                                <Col>
                                    <h6 onClick={() => handleDropdownClick('changeEmail')}>Change Email</h6>
                                </Col>
                                <Col xs={2}>
                                    <img src="https://i3.lensdump.com/i/tBIjNC.png" width="24px" 
                                    style={{ transform: dropdown.changeEmail ? "rotate(180deg)" : "none"}}
                                    onClick={() => handleDropdownClick('changeEmail')}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.CardSection}>
                            <Row>
                                <Col>
                                    <h6 onClick={() => handleDropdownClick('deleteAccount')}>Delete Account</h6>
                                </Col>
                                <Col xs={2}>
                                    <img src="https://i3.lensdump.com/i/tBIjNC.png" width="24px" 
                                    style={{ transform: dropdown.deleteAccount ? "rotate(180deg)" : "none"}}
                                    onClick={() => handleDropdownClick('deleteAccount')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
                :
                <Navigate to="/login"/>
            }
        </div>
    )
};

export default Settings;